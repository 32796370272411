<template>
  <div>
    <AboutUs />
    <OurPartner />
    <BankApp />
    <BankServices />
    <NewsUpdates :news="news" />
    <ContactUs />
  </div>
</template>

<script>
import AboutUs from './HomeSections/AboutUs.vue';
import BankApp from './HomeSections/BankApp.vue';
import BankServices from './HomeSections/BankServices.vue';
import ContactUs from './HomeSections/ContactUs.vue';
import NewsUpdates from './HomeSections/NewsUpdates.vue';
import OurPartner from './HomeSections/OurPartner.vue';
import Layout from '@WebsiteShared/MainLayout.vue';


export default {
  components: {
    AboutUs,
    OurPartner,
    BankApp,
    BankServices,
    ContactUs,
    NewsUpdates
  },
  layout: Layout,
  props: {
    news: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      appUrl: import.meta.env.VITE_APP_URL || 'https://default-url.com', // Default if not set
    };
  },
  mounted() {
    // Log the news data to the console
    // console.log('News:', this.news);
    // console.log('Slaider:', this.sliders);
    // console.log('logo:', this.logo);
    // console.log('time:', this.time);
    // console.log('website_name:', this.website_name);
  },
}
</script>
