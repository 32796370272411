<template>
  <div class="flex flex-col items-center mt-2 relative min-h-[40rem] mb-56">
    <div class="bg-[#f0f0e9] min-h-[30rem] w-screen">
      <div class="flex flex-col ms-8 md:mx-10 pt-10 md:p-16 relative">
        <img
          class="w-32 ms-20"
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
        >
        <h1 class="text-[#284478] max-md:text-2xl mt-4">
          {{ $t('home.news.title') }}
        </h1>
        <p class="mt-4 max-sm:text-sm text-[#626c83]">
          {{ $t('home.news.subtitle') }}
        </p>
        <!-- Use LinkButton component -->
        <LinkButton
          :text="$t('home.news.btn')"
          link="/news"
        />
      </div>
      <!-- Message when no news is available -->
      <div
        v-if="!news.length"
        class="bg-[#f0f0e9]  w-screen text-center mt-8"
      >
        <h2 class="text-2xl text-[#284478]">
          {{ $t('home.news.no_news_title') }}
        </h2>
        <p class="mt-4 max-sm:text-sm text-[#626c83]">
          {{ $t('home.news.no_news_message') }}
        </p>
      </div>
    </div>
    <div class="w-[80dvw] max-lg:w-[90vw] -mt-72 max-md:w-full">
      <Carousel
        v-if="news.length"
        class="w-full"
        :wrap-around="false"
        :snap-align="snapAlign"
        :items-to-scroll="itemsToShow"
        :items-to-show="itemsToShow"
        :breakpoints="carouselBreakpoints"
      >
        <Slide
          v-for="(item, i) in news"
          :key="i"
          :dir="locale === 'ar' ? 'rtl' : 'ltr'"
        >
          <div class="w-full pt-32 pb-20">
            <NewsCard
              :content="item"
              :is-full-card-link-enabled="false"
            />
          </div>
        </Slide>
        <template #addons>
          <div
            v-if="locale == 'ar'"
            class="absolute top-10 left-4 max-md:top-6 max-sm:top-4"
          >
            <Navigation class="flex bg-[#00000019] text-[#284478] p-1 md:p-2 rounded-full hover:bg-[#00000033]  " />
          </div>
          <div
            v-else
            class="absolute top-8 md:top-12 max-sm:top-10 right-4"
            :class="locale != 'ar' ? 'right-4 top-4 ' : 'left-4 sm:top-8 '"
          >
            <Navigation class="flex bg-[#00000019] text-[#284478] p-1 md:p-2 rounded-full hover:bg-[#00000033]  " />
          </div>
          <Pagination class="golden-pag" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import LinkButton from '@components/LinkButton.vue';
import NewsCard from '@WebsiteShared/components/NewsCard.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

export default {
  components: {
    LinkButton,
    NewsCard,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  props: {
    news: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const { locale } = useI18n();
    const itemsToShow = ref(4);
    const snapAlign = ref('center');
    const carouselBreakpoints = {
      1400: { itemsToShow: 4, snapAlign: 'start', itemsToScroll: 4 },
      1200: { itemsToShow: 3, snapAlign: 'center', itemsToScroll: 3 },
      600: { itemsToShow: 2, snapAlign: 'start', itemsToScroll: 2 },
      0: { itemsToShow: 1.2, snapAlign: 'center', itemsToScroll: 1 },
    };

    return {
      locale,
      itemsToShow,
      snapAlign,
      carouselBreakpoints,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
