<template>
  <AboutTheBank
    :content="{ title: 'terms_conditions', count: 10 }"
    title-style="number"
  >
    <template #default>
      <p class="text-red">
        This is some content that appears between the title and the list.
      </p>
    </template>
  </AboutTheBank>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';

export default {
  components: {
    AboutTheBank,
  },
  layout: Layout,
}
</script>

<style scoped></style>