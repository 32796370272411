<template>
  <div class="w-screen relative primary-bg-color">
    <!-- Only render Carousel if sliders is not empty -->
    <Carousel
      v-if="sliders && sliders.length > 0"
      :autoplay="5000"
      :wrap-around="true"
      :dir="direction"
    >
      <Slide
        v-for="(slide, index) in sliders"
        :key="slide.id"
      >
        <div class="carousel__item flex items-center justify-center">
          <!-- Load the first image eagerly (no lazy loading) -->
          <img
            :src="getImage(slide)"
            :alt="slide.name"
            class="w-full object-cover max-h-[630px] min-h-[630px]"
            :loading="index === 0 ? 'eager' : 'lazy'"
          >
        </div>
      </Slide>
      <template #addons>
        <Pagination class="golden-pag absolute bottom-5 w-full" />
      </template>
    </Carousel>

    <!-- Display default content if sliders is empty -->
    <div
      v-else
      class="w-full flex items-center justify-center"
    >
      <img
        src="@assets/images/home/bg2.webp"
        alt="No slides available"
        class="w-full object-cover max-h-[630px] min-h-[630px]"
        loading="eager"
      >
      <!-- Or display a message instead of an image -->
      <!-- <p>No slides available at the moment.</p> -->
    </div>
  </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

// Import the getDirection function from your i18n setup
import { getDirection } from '@/i18n';

const { props } = usePage();
const { locale } = useI18n();

const sliders = props.sliders || []; // Ensure sliders is an array

// Compute the direction based on the locale using getDirection
const direction = computed(() => getDirection(locale.value));

const getImage = (slide) => {
  return locale.value === 'ar' ? slide.image_ar : slide.image_en;
};
</script>

<style scoped>
.carousel__item {
  height: 100%;
  width: 100%;
}
</style>
