<template>
  <div class="flex justify-center w-full px-12"> 
    <div class="container flex flex-col items-center rounded-xl px-2 py-10 justify-evenly bg-white max-md:rounded-b-none">
      <!-- Title -->
      <h2 class="text-lg font-semibold text-blue-900 w-full px-10 max-md:px-1">
        {{ $t('branch.filter.title') }}
      </h2>
      <div class="flex items-center justify-between w-full px-10 max-md:flex-col max-md:px-1">
        <!-- Address Input -->
        <div class="flex flex-col items-start w-1/6 max-xl:w-3/12 max-md:w-full">
          <label
            class="text-blue-900 text-sm mb-1"
            for="address"
          >{{ $t('branch.filter.address') }}</label>
          <input
            id="address"
            v-model="filters.search"
            type="text"
            :placeholder="$t('branch.filter.address_placeholder')"
            class="w-full h-10 px-3 rounded-xl border border-gray-300 placeholder-gray-400 text-gray-600 focus:outline-none focus:border-blue-500 max-md:mb-4"
            @input="applyFilters"
          >
        </div>

        <!-- Governorate Filter -->
        <div class="flex flex-col items-start w-1/6 max-xl:w-3/12 max-md:w-full">
          <label
            class="text-blue-900 text-sm mb-1"
            for="governorate"
          >{{ $t('branch.filter.governorate') }}</label>
          <select
            id="governorate"
            v-model="filters.governorate"
            class="w-full h-10 px-3 rounded-xl border border-gray-300 text-gray-600 focus:outline-none focus:border-blue-500 max-md:mb-4"
            :class="locale == 'ar' ? 'select-position-ar' : 'select-position-en'"
            @change="applyFilters"
          >
            <!-- Default option for resetting the filter -->
            <option value="">
              {{ $t('branch.filter.all_governorates') }}
            </option>
            <option
              v-for="governorate in governorates"
              :key="governorate"
              :value="governorate"
            >
              {{ governorate }}
            </option>
          </select>
        </div>

        <!-- Type Filter -->
        <div class="flex flex-col items-start w-1/6 max-xl:w-3/12 max-md:w-full">
          <label
            class="text-blue-900 text-sm mb-1"
            for="type"
          >{{ $t('branch.filter.type') }}</label>
          <select
            id="type"
            v-model="filters.type"
            class="w-full h-10 px-3 rounded-xl border border-gray-300 text-gray-600 focus:outline-none focus:border-blue-500"
            :class="locale == 'ar' ? 'select-position-ar' : 'select-position-en'"
            @change="applyFilters"
          >
            <option value="">
              {{ $t('branch.filter.all_types') }}
            </option>
            <option value="Branch">
              {{ $t('branch.types.branch') }}
            </option>
            <option value="ATM">
              {{ $t('branch.types.atm') }}
            </option>
            <option value="BranchAndATM">
              {{ $t('branch.types.branch_and_atm') }}
            </option>
          </select>
        </div>

        <!-- Apply Filters Button -->
        <div class="flex items-end max-md:w-full">
          <button
            class="primary-bg-color mt-6 text-white md:w-40 w-32 h-8 rounded-xl flex items-center justify-around relative  overflow-hidden  z-10 max-md:w-40"
            @click="applyFilters"
          >
            {{ $t('branch.filter.show_results') }}
            <img
              src="@assets/images/icons/left-arrow.svg"
              :class="locale == 'ar' ? '' : 'rotate-180'"
              alt="logo"
            >
            <img
              src="@assets/images/home/main-pattern.svg"
              alt="logo"
              class="absolute w-20 top-0 opacity-60"
              :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'; // Import useI18n to access locale


export default {
  props: {
    governorates: {
      type: Array,
      required: true,
    },
  },
  emits: ['apply-filters'],
  setup(){
    // Get the current locale
const { locale } = useI18n();
return { locale }
  },
  data() {
    return {
      filters: {
        search: '',
        governorate: '',
        type: '',
      },
    };
  },
  methods: {
    applyFilters() {
      this.$emit('apply-filters', { ...this.filters });
    },
  }
};
</script>
