<template>
  <AboutTheBank
    :content="{ title: 'business_financing', count: 2 }"
    title-style=""
  />
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';
export default {
  components: {
    AboutTheBank
  },
  layout: Layout,
}
</script>

<style scoped></style>