<template>
  <li
    ref="dropdown"
    @click="toggleDropdown"
  >
    <a
      class="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug text-[#40444c] hover:opacity-75"
      href="#"
    >
      <span class="ml-2 text-center">{{ title }}</span>
      <img src="@assets/images/icons/arrow-down.svg">
    </a>
    <div
      v-if="isOpen"
      class="absolute mt-2 z-30 bg-white rounded-b-2xl shadow w-88 p-6 flex justify-center"
    >
      <ul
        :class="[
          'py-2 grid grid-flow-col text-sm text-gray-700 dark:text-gray-400',
          isLastItemClass ? (isPublicIdJob === null ? 'grid-rows-1' : 'grid-rows-2') : 'grid-rows-3',
        ]"
      >
        <li
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <!-- Conditionally render Link or a based on link_open value -->
          <Link
            v-if="item.link_open === 'inside'"
            :href="item.link"
            :class="[{ 'font-bold text-primary': isActive(item.link) }, 'block px-4 py-2 hover:font-bold primary-color']"
            @click="closeDropdown"
          >
            {{ $t(item.title) }}
          </Link>
          <a
            v-else
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
            class="block px-4 py-2 hover:font-bold primary-color"
            @click="closeDropdown"
          >
            {{ $t(item.title) }}
          </a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import { Link, usePage } from '@inertiajs/vue3';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  components: {
    Link,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    isPublicIdJob: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isOpen = ref(false);
    const dropdown = ref(null);

    const toggleDropdown = () => {
      document.dispatchEvent(new CustomEvent('closeDropdowns'));
      isOpen.value = !isOpen.value;
    };

    const closeDropdown = () => {
      isOpen.value = false;
    };

    const handleClickOutside = (event) => {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        isOpen.value = false;
      }
    };

    const isActive = (link) => {
      const page = usePage();
      return page.url === link;
    };

    const isLastItemClass = () => {
      return props.menuItems.length > 0 && props.menuItems.length <= 3;
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('closeDropdowns', closeDropdown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('closeDropdowns', closeDropdown);
    });

    return {
      isOpen,
      dropdown,
      toggleDropdown,
      closeDropdown,
      handleClickOutside,
      isActive,
      isLastItemClass,
    };
  },
};
</script>
