/* eslint-disable vue/no-dupe-keys */
<template>
  <div class="flex flex-col gap-10">
    <div class="primary-bg-color rounded-[3rem] p-10 max-sm:p-4">
      <form
        class="bg-white w-full flex flex-col gap-7 rounded-[3rem] z-50 py-10 px-10 max-sm:px-4"
        @submit.prevent="submit"
      >
        <div class="flex max-md:flex-col gap-2">
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.full_name') }}
            </p>
            <input
              v-model="form.name"
              :placeholder="$t('careers.employment_form.full_name_placeholder')"
              class="rounded-2xl ps-2 border w-full h-12"
              type="text"
            >
            <span
              v-if="form.errors.name"
              class="text-red-500"
            >{{ form.errors.name }}</span>
          </div>
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.gender') }}
            </p>
            <div class="flex items-center h-full gap-10">
              <label>
                <input
                  v-model="form.gender"
                  type="radio"
                  value="Male"
                  name="Male"
                >
                {{ $t('careers.employment_form.male') }}
              </label>
              <label>
                <input
                  v-model="form.gender"
                  type="radio"
                  value="Female"
                  name="Female"
                >
                {{ $t('careers.employment_form.female') }}
              </label>
            </div>
            <span
              v-if="form.errors.gender"
              class="text-red-500"
            >{{ form.errors.gender }}</span>
          </div>
        </div>
        <div class="flex max-md:flex-col gap-2">
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.berth_date') }}
            </p>
            <input
              v-model="form.birthday"
              :placeholder="$t('careers.employment_form.berth_date_placeholder')"
              class="rounded-2xl p-2 border w-full h-12"
              type="date"
            >
            <span
              v-if="form.errors.birthday"
              class="text-red-500"
            >{{ form.errors.birthday }}</span>
          </div>
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.nationality') }}
            </p>
            <input
              v-model="form.nationality"
              :placeholder="$t('careers.employment_form.nationality_placeholder')"
              class="rounded-2xl ps-2 border w-full h-12"
              type="text"
            >
            <span
              v-if="form.errors.nationality"
              class="text-red-500"
            >{{ form.errors.nationality }}</span>
          </div>
        </div>
        <div class="flex max-md:flex-col gap-2">
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.number') }}
            </p>
            <input
              v-model="form.mobile"
              :placeholder="$t('careers.employment_form.number_placeholder')"
              class="rounded-2xl ps-2 border w-full h-12"
              type="number"
            >
            <span
              v-if="form.errors.mobile"
              class="text-red-500"
            >{{ form.errors.mobile }}</span>
          </div>
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.location') }}
            </p>
            <input
              v-model="form.address"
              :placeholder="$t('careers.employment_form.location_placeholder')"
              class="rounded-2xl ps-2 border w-full h-12"
              type="text"
            >
            <span
              v-if="form.errors.address"
              class="text-red-500"
            >{{ form.errors.address }}</span>
          </div>
        </div>
        <div class="flex max-md:flex-col gap-2">
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.education_level') }}
            </p>
            <select
              v-model="form.education"
              class="rounded-2xl px-2 border bg-white w-full h-12"
              :class="locale == 'ar' ? 'select-position-ar' : 'select-position-en'"
            >
              <option
                :value="null"
                disabled
                hidden
                class="secondary-font"
              >
                {{ $t('careers.employment_form.choose') }}
              </option>
              <option
                v-for="item in educationLevel"
                :key="item.id"
                :value="item.level"
              >
                {{ item.level }}
              </option>
            </select>

            <span
              v-if="form.errors.education"
              class="text-red-500"
            >{{ form.errors.education }}</span>
          </div>
          <div class="w-1/2 max-md:w-full flex flex-col gap-2">
            <p class="primary-color">
              {{ $t('careers.employment_form.email') }}
            </p>
            <input
              v-model="form.email"
              :placeholder="$t('careers.employment_form.email_placeholder')"
              class="rounded-2xl ps-2 border w-full h-12"
              type="email"
            >
            <span
              v-if="form.errors.email"
              class="text-red-500"
            >{{ form.errors.email }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <p class="primary-color">
            {{ $t('careers.employment_form.cv') }}
          </p>
          <div
            class="rounded-2xl p-4 border w-full flex items-center justify-between cursor-auto"
          >
            <p
              class="secondary-font text-sm w-11/12"
              @click="selectedFile()"
            >
              {{ fileName ||$t('careers.employment_form.cv_placeholder') }}
            </p>
            <img
              v-if="fileName"
              src="@assets/images/icons/cancel-file.svg"
              alt="file"
              @click="deleteFile"
            >
            <img
              v-else
              src="@assets/images/icons/document-attachment.svg"
              alt="file"
            >
          </div>
          <input
            ref="fileInput"
            type="file"
            class="hidden"
            accept=".docx, .pdf"
            @change="onFileSelected"
          >
          <span
            v-if="form.errors.cv"
            class="text-red-500"
          >{{ form.errors.cv }}</span>
        </div>
        <div class="flex flex-col gap-2">
          <p class="primary-color">
            {{ $t('careers.employment_form.skills') }}
          </p>
          <textarea
            v-model="form.skills"
            :placeholder="$t('careers.employment_form.skills_placeholder')"
            class="rounded-2xl p-4 border w-full h-40"
          />
          <span
            v-if="form.errors.skills"
            class="text-red-500"
          >{{ form.errors.skills }}</span>
        </div>
        <div class="flex items-center gap-2">
          <input
            v-model="agreed_on_conditions"
            type="checkbox"
            class="w-[15px] h-[15px]"
          >
          <p class="primary-color text-lg mt-1">
            {{ $t('careers.employment_form.agree') }}
            <span
              class="font-bold cursor-pointer"
              @click="openTermsDialog"
            >{{ $t('careers.employment_form.terms') }}</span>
          </p>
        </div>
        <SubmitBtn :is-form-valid="isFormValid" />
        <div
          v-if="flashMessage"
          class="text-green-500 mt-4"
        >
          {{ flashMessage }}
        </div>
      </form>
    </div>
    <JobTermsDialog 
      :show-terms-dialog="showTermsDialog"
      @update:showTermsDialog="showTermsDialog = $event"
    />
  </div>
</template>

<script>
import { useForm, usePage } from '@inertiajs/vue3';
import SubmitBtn from '@WebsiteShared/components/SubmitBtn.vue';
import { computed, ref, watch } from 'vue';
import JobTermsDialog from './JobTermsDialog.vue';

export default {
  components: {
    SubmitBtn,
    JobTermsDialog
  },
  props: {
    educationLevel: {
      type: Array,
      default: () => []
    },
    career: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const page = usePage(); // Access Inertia props, including the flash message

    const form = useForm({
      name: null,
      gender: 'Male',
      birthday: null,
      nationality: null,
      mobile: null,
      address: null,
      education: null,
      email: null,
      cv: null,
      skills: null
    });

    const flashMessage = computed(() => page.props.flash?.message || ''); // Safe access to flash message
    const agreed_on_conditions = ref(false);
    const fileInput = ref(null);
    const fileName = ref('');

    const isFormValid = computed(() => {
      return !!(form.name && form.gender && form.birthday && form.nationality && form.mobile && form.address && form.email && form.cv && agreed_on_conditions.value && form.cv);
    });

    const selectedFile = () => {
      fileInput.value.click();
    };

    const deleteFile = () => {
      fileName.value = '';
      form.cv = null;
    };

    const onFileSelected = (event) => {
      const file = event.target.files[0];
      form.cv = file;
      fileName.value = file.name;
    };

    const submit = () => {
      form.post(`/applicants/${props.career.id}`, {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
          setTimeout(() => {
            flashMessage.value = ''; // Clear flash message after timeout
          }, 9000);
          form.reset();
          fileName.value = ''; // Reset the form if submission is successful
        }
      });
    };


    watch(form, (newVal) => {
      console.log(newVal.education);
    }, { deep: true });

    return {
      form,
      flashMessage,  // Access computed flash message
      agreed_on_conditions,
      fileInput,
      fileName,
      selectedFile,
      deleteFile,
      onFileSelected,
      submit,
      isFormValid
    };
  },
  data() {
    return {
      showTermsDialog: false,  // Dialog visibility state
    };
  },
  methods: {
    openTermsDialog() {
      this.showTermsDialog = true;  // Show the dialog when the button is clicked
    }
  }
};
</script>

