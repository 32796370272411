<template>
  <div>
    <h1>This is the store applicants page</h1>
    <form @submit.prevent="handleSubmit">
      <input
        v-model="applicantName"
        type="text"
        placeholder="Applicant Name"
        required
      >
      <!-- Add name attribute for the CSRF token -->
      <input
        type="hidden"
        name="_token"
        :value="csrfToken"
      >
      <button type="submit">
        Submit
      </button>
    </form>
  </div>
</template>

<script>


export default {
  props: {
    csrfToken:  {
    type:String,
    default: "",
    },
  },
  data() {
    return {
      form: this.$inertia.form({
        first_name: '',
        last_name: '',
        organization_id: null,
        email: '',
        phone: '',
        address: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
      }),
    }
  },
  methods: {
    handleSubmit() {
      // Use Inertia to post data to the route
      this.form.post('/applicants', {
        applicant_name: this.applicantName,
        _token: this.csrfToken, // Although this is included in the form, it's still good practice to send it
      });
    },
  },
};
</script>
