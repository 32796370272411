<template>
  <Head>
    <!-- Title -->
    <title>{{ seoData.title }}</title>

    <!-- Meta Description -->
    <meta
      name="description"
      :content="seoData.meta_description"
      head-key="description"
    >

    <!-- Meta Keywords -->
    <meta
      name="keywords"
      :content="seoData.keywords"
      head-key="keywords"
    >

    <!-- Robots Tag -->
    <meta
      name="robots"
      content="index, follow"
      head-key="robots"
    >

    <!-- Open Graph Tags -->
    <meta
      property="og:title"
      :content="seoData.title"
      head-key="og:title"
    >
    <meta
      property="og:description"
      :content="seoData.meta_description"
      head-key="og:description"
    >
    <meta
      property="og:type"
      content="website"
      head-key="og:type"
    >
    <meta
      property="og:url"
      :content="canonicalUrl"
      head-key="og:url"
    >
    <meta
      property="og:image"
      :content="seoData.og_image"
      head-key="og:image"
    >

    <!-- Twitter Card Tags -->
    <meta
      name="twitter:card"
      content="summary_large_image"
      head-key="twitter:card"
    >
    <meta
      name="twitter:title"
      :content="seoData.title"
      head-key="twitter:title"
    >
    <meta
      name="twitter:description"
      :content="seoData.meta_description"
      head-key="twitter:description"
    >
    <meta
      name="twitter:image"
      :content="seoData.og_image"
      head-key="twitter:image"
    >

    <!-- Canonical Link -->
    <link
      rel="canonical"
      :href="canonicalUrl"
      head-key="canonical"
    >
  </Head>
</template>

<script>
import { Head, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Head,
  },
  setup() {
    const { t } = useI18n();
    const page = usePage(); // Get the reactive page object

    // Computed property for SEO data, derived from page.props.seo
    const seoData = computed(() => {
      console.log("computed seo ", page.props.seo);

      return page.props?.seo || {
        title: t('seo.home.title'),
        meta_description:  t('seo.home.description'), 
        keywords: t('seo.home.keywords'),
        og_image: '@assets/images/header/web/general.jpg',
      };
    });

    const canonicalUrl = computed(() => {
      return page.props.page_url || 'https://www.hadhramoutbank.com';
    });

    return {
      seoData,
      canonicalUrl,
    };
  },
  mounted() {
    // Inject JSON-LD structured data dynamically
    const jsonLdScript = document.createElement('script');
    jsonLdScript.type = 'application/ld+json';
    jsonLdScript.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": this.seoData.title,
      "description": this.seoData.meta_description,
      "url": this.canonicalUrl,
      "image": this.seoData.og_image,
    });
    document.head.appendChild(jsonLdScript);
  },
};
</script>
