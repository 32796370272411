<template>
  <AboutTheBank
    :content="{ title: 'internet_banking', count: 2 }"
    title-style=""
  />
  <div class="flex items-center justify-center">
    <a
      href="https://mobile.hadhramoutbank.com/IBS/"
      target="_blank"
    >
      <button
        class="primary-bg-color mt-8 text-white md:w-52 w-40 h-12 rounded-2xl flex items-center justify-around relative  overflow-hidden hover:w-60 transition-all duration-500 ease-in-out z-10"
      >
        {{ $t('internet_banking.internet_banking_link') }}
        <img
          src="@assets/images/icons/left-arrow.svg"
          :class="locale == 'ar' ? '' : 'rotate-180'"
          alt="logo"
        >
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="absolute w-20 top-0 opacity-60"
          :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
        >
      </button>
    </a>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';

export default {
  components: {
    AboutTheBank,
  },
  layout: Layout,
}
</script>

<style scoped></style>