<template>
  <div class="primary-bg-color flex flex-col">
    <div class="flex flex-col items-start w-full pt-16 ps-10 md:p-24 md:pb-10 relative">
      <div class="flex flex-col items-center">
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="w-24 mb-2"
        >
        <h1 class="text-[2.5rem] my-2">
          {{ $t('home.our_partner.title') }}
        </h1>
      </div>
      <p class="mt-4 max-sm:text-sm">
        {{ $t('home.our_partner.description') }}
      </p>
    </div>

    <div class="my-16">
      <!-- Bind direction to Carousel based on computed property -->
      <Carousel
        :autoplay="3000"
        :wrap-around="true"
        :dir="direction"
        snap-align="start"
        :items-to-show="1.3"
        :breakpoints="{ 768: { itemsToShow: 3.5 } }"
      >
        <Slide
          v-for="slide in images"
          :key="slide.id"
        >
          <div class="carousel__item flex items-center justify-center">
            <img
              :src="slide.path"
              alt="logo"
              class="object-cover w-[250px] h-auto pe-4"
            >
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

// Import getDirection from your i18n configuration file
import { getDirection } from '@/i18n';

const { locale } = useI18n();

// Compute the direction dynamically based on the current locale
const direction = computed(() => getDirection(locale.value));

// Define images array with computed property for dynamic imports
const images = computed(() => [
  { path: new URL(`@assets/images/home/partners/atb.png`, import.meta.url).href },
  {path: new URL(`@assets/images/home/partners/grant.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/ics.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/csc.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/mastercard.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/efg.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/ncr.png`, import.meta.url).href},
  {path: new URL(`@assets/images/home/partners/oracle.png`, import.meta.url).href},
]);
</script>
