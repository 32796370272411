<template>
  <div class="flex justify-evenly mt-2 p-4 relative max-md:flex-col">
    <div
      class="flex max-lg:flex-col-reverse min-h-[30rem] w-8/12 max-xl:w-full p-10 max-md:p-3 primary-bg-color rounded-[3rem]"
    >
      <!-- <img class="absolute bottom-10  right-[40%]"src="@assets/images/home/squares-3.svg" alt=""> -->
      <div class="mx-10 max-md:mx-5 mt-10 w-1/2 max-lg:w-full flex flex-col gap-10">
        <div class="flex flex-col items-start gap-3 max-lg:hidden">
          <div class="flex flex-col items-center">
            <img
              src="@assets/images/home/main-pattern.svg"
              alt="logo"
              class="w-32 mb-4"
            >

            <h1 class="">
              {{ $t('home.contact_us.title') }}
            </h1>
          </div>
          <p class="lighten-gray-color">
            {{ $t('home.contact_us.subtitle') }}
          </p>
        </div>
        <div class="flex flex-col gap-3">
          <p class="max-sm:text-[16px]">
            {{ $t('home.contact_us.call_us') }}
          </p>
          <div class="flex flex-col gap-6">
            <!-- Email Link -->
            <div class="flex items-center gap-2">
              <div class="min-w-11 min-h-11 flex items-center justify-center rounded-[20px] bg-white">
                <img
                  src="@assets/images/icons/mail.svg"
                  alt=""
                >
              </div>
              <div class="flex flex-col gap-3">
                <p class="max-sm:text-[16px]">
                  {{ $t('home.contact_us.email') }}
                </p>
                <a
                  :href="`mailto:${props.settings.general.support_email}`"
                  class="multi-line text-white max-sm:text-[12px]"
                >
                  {{ props.settings.general.support_email }}
                </a>
              </div>
            </div>

            <!-- Mobile Link -->
            <div class="flex items-center gap-2">
              <div class="min-w-11 min-h-11 flex items-center justify-center rounded-[20px] bg-white">
                <img
                  src="@assets/images/icons/call.svg"
                  alt=""
                >
              </div>
              <div class="flex flex-col gap-3">
                <p class="max-sm:text-[16px]">
                  {{ $t('home.contact_us.suppots') }}
                </p>
                <a
                  :href="`tel:${props.settings.general.mobile}`"
                  class="multi-line text-white max-sm:text-[12px]"
                >
                  {{ props.settings.general.mobile }}
                </a>
              </div>
            </div>

            <!-- Location Link -->
            <div class="flex items-center gap-2">
              <div class="min-w-11 min-h-11 flex items-center justify-center rounded-[20px] bg-white">
                <img
                  src="@assets/images/icons/location.svg"
                  alt=""
                >
              </div>
              <div class="flex flex-col gap-3">
                <p class="max-sm:text-[16px]">
                  {{ $t('home.contact_us.location') }}
                </p>
                <a
                  :href="props.settings.general.location_url"
                  target="_blank"
                  class="multi-line text-white max-sm:text-[12px]"
                >
                  {{ props.settings.general.location_address }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-5 max-md:mx-8">
          <p class="max-sm:text-[12px]">
            {{ $t('home.contact_us.social_media') }}
          </p>
          <SocialMediaLinks />
        </div>
      </div>
      <div class=" w-1/2 max-lg:w-full flex flex-col gap-10 mt-4">
        <div class="flex flex-col items-start gap-3 lg:hidden">
          <div class="flex flex-col items-center">
            <img
              src="@assets/images/home/main-pattern.svg"
              alt="logo"
              class="w-32 mb-4"
            >

            <h1 class="">
              {{ $t('home.contact_us.title') }}
            </h1>
          </div>
          <p class="lighten-gray-color">
            {{ $t('home.contact_us.subtitle') }}
          </p>
        </div>
        <ContactUsForm />
      </div>
    </div>
  </div>
</template>

<script setup>
import SocialMediaLinks from '@components/SocialMediaLinks.vue';
import { usePage } from '@inertiajs/vue3';

import ContactUsForm from '../Cards/ContactUsForm.vue';
const { props } = usePage();
</script>
<style scoped></style>
