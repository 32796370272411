<template>
  <div class="w-screen">
    <Carousel
      :autoplay="5000"
      :wrap-around="true"
    >
      <Slide
        v-for="slide in images"
        :key="slide.id"
        dir="rtl"
      >
        <div class="carousel__item flex items-center justify-center">
          <img
            :src="slide.path"
            alt="logo"
            class="object-cover w-full h-full"
          >
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
const images = ref([{ path: '@assets/images/home/bg.svg' }, { path: '@assets/images/home/bg2.webp' }, { path: '@assets/images/home/bg3.webp' }, { path: '@assets/images/home/bg4.webp' },])
</script>

<style scoped>
.carousel__item {
  height: 100%;
  /* Full height */
  width: 100%;
  /* Full width */
}
</style>
