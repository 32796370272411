<template>
  <div>
    <div
      class="flex items-center cursor-pointer"
      @click="toggleLanguage"
    >
      <img
        src="@assets/images/icons/language.svg"
        alt="language"
        class="language-icon"
        :class="{'filter-white': isMobileNav}"
      >
    </div>
  </div>
</template>

<script>
import { setDirection } from '@/i18n';
import { usePage } from '@inertiajs/vue3';

export default {
  setup() {
    const { props } = usePage();
    const currentLanguage = props.locale||localStorage.getItem('locale');
    const locale =props.locale|| localStorage.getItem('locale');
    return {
      locale,
      currentLanguage
    };
  },
  data(){
    return {
      isMobileNav: false
    }
  },
  watch: {
    '$i18n.locale'(currentLanguage) {
      setDirection(currentLanguage);
      this.setLanguage(currentLanguage);
    }
  },
  mounted() {    
    setDirection(this.locale);
    this.setLanguage(this.locale);


    // Add a listener to detect screen width changes
    window.addEventListener('resize', this.checkMobileNav);
    this.checkMobileNav(); // Initial check
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileNav);
  },
  
  methods: {
    
    toggleLanguage() {
       // Toggle between 'ar' and 'en'
       const newLanguage = this.currentLanguage === 'ar' ? 'en' : 'ar';
      // Use Inertia form to send request to backend
      this.$inertia.post('/set-locale', { locale: newLanguage }, {
        onSuccess: () => {
          console.log(localStorage.getItem('locale'));
          this.setLanguage(newLanguage);
          // Force a full page reload after locale change
  

        },
        onError: () => {

          window.location.reload();

        },
        onFinish:()=>{
          localStorage.setItem('locale', newLanguage);
          window.location.reload();
        }
   
      });

      // this.setLanguage(newLanguage); // Change language to English
      // this.currentLanguage = newLanguage;

    },
    setLanguage(language) {
      this.$i18n.locale =language;
    },
    checkMobileNav() {
      this.isMobileNav = window.innerWidth <= 1024; // Adjust based on your breakpoint
    }
  }
};
</script>

<style lang="scss" scoped>
.language-icon {
  transition: filter 0.3s;
}

/* Filter for white color */
.filter-white {
  filter: invert(100%) brightness(2);
}
</style>
