<template>
  <div class="relative min-h-64 mx-5">
    <div
      class="flex flex-col text-end p-7 items-start justify-between min-h-full rounded-3xl absolute min-w-full border-2 "
      :class="index%2!==0?'primary-bg-color':'bg-[#edf9ff]'"
    >
      <img
        class="w-16"
        src="@assets/images/logos/aboutLogo.svg"
        alt
      >
      <h1
        class="text-2xl"
        :class="index%2!==0?'text-white':'text-[#284478]'"
      >
        {{ content.title }}
      </h1>

      <p
        class="text-xl whitespace-nowrap"
        :class="index%2!==0?'text-white':'text-[#284478]'"
      >
        {{ content.due_date }}
      </p>

      <!-- "Read More" Section -->
      <Link
        :href="`/careers/${content.slug}`"
        class="text-[#daab00] flex items-center gap-1"
      >
        <p class="text-[#daab00] text-lg">
          {{ $t('careers.career_card.apply') }}
        </p>
        <img
          class="w-7"
          :class="locale=='ar'?'':'rotate-180'"
          src="@assets/images/icons/arrow-left-yellow.png"
          alt
        >
      </Link>
    </div>
  </div>
</template>
<script>
import { Link } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

export default {
    components: { Link },
    props: {
        content:{
            type:Object,
            default:()=>{},
        },
        index:{
          type: Number,
          default: 0, // Set a default number if needed
        }
    },
    setup() {
        const {locale} = useI18n()
        return {
            locale
        }
    }
}
</script>
<style scoped lang="scss">
</style>