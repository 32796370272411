<template>
  <div class="mt-16">
    <h1 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
      {{ $t(`${content.file_name}.fees_and_charges.title`) }}
    </h1>
    <div class="w-full px-7 md:px-10 xl:px-2 py-4 ">
      <div class="mx-auto w-full max-w-5xl my-8 ">
        <div class="overflow-hidden">
          <table class="w-full ">
            <thead class="text-3xl max-sm:text-xl text-[#284478] font-bold">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-4"
                >
                  {{ $t(`${content.file_name}.fees_and_charges.fees`) }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-4"
                >
                  {{ $t(`${content.file_name}.fees_and_charges.banking_service`) }}
                </th>
              </tr>
            </thead>
            <tbody class="text-2xl max-sm:text-lg max-w-full text-[#40444c] text-center">
              <tr
                v-for="(row, index) in rows"
                :key="row.id"
                :class="{ 'bg-[#f9fafb]': index % 2 === 0, 'bg-white': index % 2 !== 0 }"
              >
                <td class="max-sm:text-base px-6 py-4">
                  {{ $t(row.service) }}
                </td>
                <td class="max-sm:text-base px-6 py-4">
                  {{ $t(row.fee) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      content: {
      type:Object,
      default: ()=>({}),
    },
      rows: {
      type:Array,
      default: ()=>[],
    },
  },
};
  </script>
  