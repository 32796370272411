<template>
  <div class="page">
    <!-- Pass the reactive SEO data to SeoHead component -->
    <SeoHead />
    <NavBar />
    <!-- Only show headerComponent if status is not one of [500, 503, 404, 403] -->
    <Component
      :is="headerComponent"
      v-if="headerComponent"
      :page="page"
    />
    <main>
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script>
import { usePage } from '@inertiajs/vue3';
import Footer from './FooterLayout.vue';
import HomeHeader from './HomeHeaderLayout.vue';
import NavBar from './NavbarSections/NavBar.vue';
import PageHeader from './PageHeaderLayout.vue';
import SeoHead from './SeoHeadLayout.vue';

export default {
  name: 'MainLayout',
  components: {
    Footer,
    NavBar,
    HomeHeader,
    PageHeader,
    SeoHead,
  },
  props: {
    page: {
      type: String,
      default: 'home',
    },
  },
  computed: {
    headerComponent() {
      const { status } = this.$page.props; // Get the status from props

      // If status is one of [500, 503, 404, 403], do not return a header component
      if ([500, 503, 404, 403].includes(status)) {
        return null;
      }
      console.log(this.page);
      // Return 'HomeHeader' or 'PageHeader' based on the page prop
      return this.page === 'home' ? 'HomeHeader' : this.page.includes('show') ?'':'PageHeader';
    },
  },
  mounted() {
    const { props } = usePage();
    console.log(props);
    
  },
};
</script>

<style scoped>
main {
  padding-bottom: 1rem;
}

.page {
  overflow: hidden;
}
</style>
