<template>
  <div class="relative w-full h-screen">
    <!-- Branch Filter -->
    <div class="absolute top-5 z-index-420 mx-auto w-full">
      <BranchFilter
        :filters="filters"
        :governorates="governorates"
        :branch-types="branchTypes"
        @apply-filters="updateFilters"
      />
    </div>

    <!-- Leaflet Map -->
    <LMap
      ref="mapComponent"
      :zoom="9"
      :options="mapOptions"
      :center="[14.5425, 49.1256]"
      class="w-full h-full relative rounded-lg"
      @ready="onMapReady"
    >
      <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <!-- Branch Markers -->
      <LMarker
        v-for="branch in allBranches"
        :key="branch.id"
        :lat-lng="[branch.latitude, branch.longitude]"
        :icon="createMarkerIcon(branch.type)"
        class="z-index-420"
        @click="panToBranch(branch)"
      >
        <LPopup>
          <BranchMap :branch="branch" />
        </LPopup>
      </LMarker>

      <!-- Branch List as Custom Control -->
      <LControl class=" top-60">
        <BranchList
          :branches="allBranches"
          :is-list-open="isListOpen"
          @toggle-list="toggleList"
          @center-map="panToBranch"
          @update:screen-width="handleScreenWidthChange"
        />
      </LControl>

      <!-- Custom Zoom Controls -->
      <div class="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex flex-row gap-2 z-index-420">
        <button
          aria-label="Zoom In"
          class="bg-white border border-gray-300 w-10 h-10 flex items-center justify-center text-2xl rounded shadow hover:bg-gray-100 transition"
          @click="zoomIn"
        >
          +
        </button>
        <button
          aria-label="Zoom Out"
          class="bg-white border border-gray-300 w-10 h-10 flex items-center justify-center text-2xl rounded shadow hover:bg-gray-100 transition"
          @click="zoomOut"
        >
          -
        </button>
      </div>
    </LMap>
  </div>
</template>

<script>
import { router } from '@inertiajs/vue3';
import { LControl, LMap, LMarker, LPopup, LTileLayer } from '@vue-leaflet/vue-leaflet';

import Layout from '@WebsiteShared/MainLayout.vue';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import { createApp, defineComponent, toRaw } from "vue";

import BranchList from './BranchList.vue';
import BranchFilter from './Cards/BranchFilter.vue';
import BranchMap from './Cards/BranchMap.vue';

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    BranchFilter,
    BranchList,
    BranchMap,
    LPopup,
    LControl
  },
  layout: Layout,
  props: {
    branches: {
      type: Object,
      required: true,
    },
    governorates: {
      type: Array,
      required: true,
    },
    branchTypes: {
      type: Object,
      required: true,
    },
  },
  emits: ['center-map'],
  data() {
    return {
      markers: [], // Initialize markers as an empty array
      allBranches: [...this.branches.data],
      isListOpen: true,
      screenWidth: window.innerWidth,
      filters: {
        governorate: '',
        type: '',
        search: '',
      },
      leafletMap: null, // Store the Leaflet map instance here
      markerLayerGroup: null, // Layer group for markers to manage them more easily
      rightClickTimeout: null, // Added for handling double right-click
      isMobile: false, // Added to track mobile status
    };
  },
  computed: {
    // Computed property to determine if the device is mobile
    isDeviceMobile() {
      return this.screenWidth < 768; // Adjust the breakpoint as needed
    },
    // Computed property for map options based on device type
    mapOptions() {
      return {
        scrollWheelZoom: false,
        zoomControl: false,
        dragging: !this.isDeviceMobile, // Disable dragging on mobile
        tap: !this.isDeviceMobile, // Disable tap on mobile to prevent conflicts
      };
    },
  },
  watch: {
    // Watcher to update screenWidth on window resize
    screenWidth(newWidth) {
      this.isMobile = newWidth < 768;
    },
  },
  mounted() {
    // Initialize isMobile based on current screen width
    this.isMobile = this.screenWidth < 768;

    // Add event listener for window resize to update screenWidth
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // Clean up event listeners when component is destroyed
    if (this.leafletMap) {
      this.leafletMap.getContainer().removeEventListener('mousedown', this.handleRightClick);
      this.leafletMap.getContainer().removeEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    }
    // Remove window resize listener
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    handleScreenWidthChange(width) {
      this.screenWidth = width;
      // Uncomment the following if you want to automatically open the list on smaller screens
      // if (width <= 780) {
      //   this.isListOpen = true;
      // }
    },
    onMapReady(mapInstance) {
      this.leafletMap = toRaw(mapInstance);
      this.markerLayerGroup = L.layerGroup().addTo(toRaw(this.leafletMap));
      this.addBranchMarkers(); // Ensure markers are added only after map is ready

      // Disable default context menu on map
      this.leafletMap.getContainer().addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      // Handle double right-click for zooming out
      this.leafletMap.getContainer().addEventListener('mousedown', this.handleRightClick);
    },

    // Toggle branch list visibility
    toggleList() {
      this.isListOpen = !this.isListOpen;
    },

    // Update filters and fetch data based on filters
    updateFilters(newFilters) {
      this.filters = { ...this.filters, ...newFilters };
      this.applyFilters();
    },

    // Apply filters by fetching updated branch data from API
    applyFilters() {
      // Assuming `router.post` is for API calls with Inertia or Vue Router
      router.post('/branches', this.filters, {
        preserveScroll: true,
        replace: true,
        onSuccess: (page) => {
          this.allBranches = page.props.branches.data;
          this.addBranchMarkers();  // Re-add markers with the filtered data
        },
      });
    },

    // Create custom marker icons based on branch type
    createMarkerIcon(branchType) {
      const colorClass = `fill-${branchType?.tailwindColor || 'gray'}-500`;
      return L.divIcon({
        className: 'custom-marker',
        html: `
          <svg class="${colorClass} w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
          </svg>
        `,
      });
    },

    // Add markers to the map for each branch in the filtered results
    addBranchMarkers() {
      this.clearMarkers();
      const bounds = [];
      this.allBranches.forEach((branch) => {
        if (branch.latitude && branch.longitude) {
          const marker = L.marker([branch.latitude, branch.longitude], {
            icon: this.createMarkerIcon(branch.type),
          });

          // Attach branch ID to the marker for easy reference
          marker.branchId = branch.id;

          // Add the marker to the layer group
          marker.addTo(toRaw(this.markerLayerGroup));
          bounds.push([branch.latitude, branch.longitude]);

          // Add popup with branch details
          const popupContent = document.createElement("div");
          const popupApp = createApp(BranchMap, { branch });
          popupApp.mount(popupContent);
          marker.bindPopup(popupContent);

          // Add an event listener to log when the popup is opened
          marker.on('popupopen', () => {
            this.$emit('center-map', branch);
            if (this.leafletMap && branch.latitude && branch.longitude) {
              const offsetAmount = -0.01; // Adjust this value to control how much down the branch should be centered
              const adjustedLat = branch.latitude - offsetAmount; // Adjust latitude to move down slightly

              toRaw(this.leafletMap).setView(
                [adjustedLat, branch.longitude],
                15,
                { animate: true }
              );
            }

            const popupElement = marker.getPopup().getElement();
            if (popupElement) {
              const popupContent = popupElement.querySelector('.leaflet-popup-content');
              if (popupContent) {
                popupContent.setAttribute('style', 'margin: 0 !important; width: 300px !important;');
              }
            }
          });

          // Add the marker to the markers array
          this.markers.push(marker);
        }
      });

      // Fit map bounds to include all markers
      if (bounds.length) {
        toRaw(this.leafletMap).fitBounds(bounds, { padding: [150, 150] });
      }
    },

    // Clear markers from the map before re-adding
    clearMarkers() {
      // Clear all markers from layer group and reset array
      if (this.markerLayerGroup) {
        toRaw(this.markerLayerGroup).clearLayers();
      }
      this.markers = [];
    },

    // Pan and zoom to a specific branch and open its popup
    panToBranch(branch) {
      if (this.leafletMap && branch.latitude && branch.longitude) {
        const offsetAmount = -0.01; // Adjust this value to control how much down the branch should be centered
        const adjustedLat = branch.latitude - offsetAmount; // Adjust latitude to move down slightly

        toRaw(this.leafletMap).setView(
          [adjustedLat, branch.longitude],
          15,
          { animate: true }
        );

        // Find the corresponding marker for the branch
        const marker = this.markers.find(m => m.branchId === branch.id);
        if (marker) {
          // Open the popup associated with the marker
          marker.openPopup();
        }
      }
    },

    // Custom Zoom In Method
    zoomIn() {
      if (this.leafletMap) {
        this.leafletMap.zoomIn();
      }
    },

    // Custom Zoom Out Method
    zoomOut() {
      if (this.leafletMap) {
        this.leafletMap.zoomOut();
      }
    },

    // Handle double right-click for zooming out
    handleRightClick(event) {
      if (event.button === 2) { // Right mouse button
        if (this.rightClickTimeout) {
          clearTimeout(this.rightClickTimeout);
          this.rightClickTimeout = null;
          this.zoomOut();
        } else {
          this.rightClickTimeout = setTimeout(() => {
            this.rightClickTimeout = null;
          }, 300); // Time window for double click (milliseconds)
        }
      }
    },
  },
});
</script>
<style scoped>
/* Retained z-index-420 class */
.z-index-420 {
  z-index: 420;
}

</style>