<template>
  <div class="bg-cover rounded-[2.5rem] relative min-h-72 mx-5 ">
    <img
      class="absolute bg-cover h-full w-full rounded-[2.5rem]"
      :src="content.img"
      :alt="content.title"
    >
  
    <div class="flex flex-col text-start justify-end gap-5 px-5 pb-5 bg-gradient-to-b from-transparent to-[#1a428a] min-h-full rounded-[2.5rem] absolute min-w-full max-w-full">
      <p class="text-2xl font-bold">
        {{ $t(content.title) }}
      </p>
      <p class="text-lg">
        {{ $t(content.description) }}
      </p>
  
      <!-- Link Component for "Read More" -->
      <Link
        :href="content.link"
        class="w-full flex items-center gap-1"
      >
        <p class="text-[#feffc2] !text-[1rem] text-lg font-semibold">
          {{ $t('home.bank_services.read_more') }}
        </p>
        <img
          class="w-6"
          :class="locale=='ar'?'':'rotate-180'"
          src="@assets/images/icons/circle-arrow-up-left.png"
          :alt="content.title"
        >
      </Link>
    </div>
  </div>
</template>
  
  <script>
  import { Link } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Link,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
};
  </script>
  
  <style scoped>
  </style>
  