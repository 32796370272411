<template>
  <!-- Conditionally wrap the entire card with the Link component based on isFullCardLinkEnabled -->
  <Component
    :is="isFullCardLinkEnabled ? 'Link' : 'div'"
    :href="isFullCardLinkEnabled ? `/news/${content.slug}` : null" 
    class="bg-cover rounded-[2.5rem] relative min-h-[22rem] mx-5 block"
  >
    <img
      class="absolute h-full w-full rounded-[2.5rem] object-fill"
      :src="content.poster"
      :alt="content.title"
    >
    
    <div class="flex flex-col text-start justify-end gap-5 px-5 pb-5 bg-gradient-to-b from-transparent to-[#1a428a] min-h-full rounded-[2.5rem] absolute min-w-full max-w-full">
      <p class="text-lg">
        {{ content.title }}
      </p>
      
      <div class="flex justify-between items-center">
        <div class="w-full flex gap-1 justify-start items-center">
          <img
            class="w-4"
            src="@assets/images/icons/calendar.png"
            alt="calendar icon"
          >
          <p class="text-[15px] ">
            {{ content.news_date }}
          </p>
        </div>
        
        <!-- "Read More" Section -->
        <Link
          :href="`/news/${content.slug}`"
          class="w-full flex items-center justify-end gap-1"
        >
          <p class="text-[#feffc2] !text-[0.9rem] text-lg font-semibold mt-1 whitespace-nowrap">
            {{ $t('news.news_card.read_more') }}
          </p>
          <img
            class="w-6"
            :class="locale=='ar'?'':'rotate-180'"
            src="@assets/images/icons/circle-arrow-up-left.png"
            alt=""
          >
        </Link>
      </div>
    </div>
  </Component>
</template>
<script>
import { Link } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Link,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    isFullCardLinkEnabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      locale: useI18n().locale,
    };
  },
}
</script>

<style scoped>
p {
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
