<template>
  <div
    class="w-screen bg-cover bg-center relative header"
    :style="{ backgroundImage: `url(${getHeaderImage})` }"
  >
    <div class="md:ps-20 pb-10 gap-4 lg:w-4/5 flex flex-col justify-end text-white pt-40 mx-8 relative z-0">
      <h1>{{ headerTitle }}</h1>
      <p class="text-[#edf9ff] text-xl whitespace-pre-line">
        {{ headerDescription }}
      </p>
      <p class="mt-4">
        <Link
          href="/"
          class="text-white hover:text-[#daab00]"
        >
          {{ $t('common.home') }}
        </Link>
        <span class="text-[#d8f0ff]">
          >
          <span v-if="!showIndividualServicesLink">{{ breadcrumbTitle }}</span>
          <template v-if="showIndividualServicesLink">
            <Link
              href="/services/individual-services"
              class="text-white hover:text-[#daab00]"
            >
              {{ locale === 'ar' ? 'الأفراد' : 'individuals' }}
            </Link>
            >
            <span>{{ breadcrumbTitle }}</span>
          </template>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    Link,
  },
  props: {
    page: {
      type: String,
      default: 'home',
    },
  },
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    headerTitle() {
      return this.$t(`page_headers.${this.page}.header_title`);
    },
    headerDescription() {
      return this.$t(`page_headers.${this.page}.header_description`);
    },
    breadcrumbTitle() {
      return this.$t(`page_headers.${this.page}.header_title`)
    },
    showIndividualServicesLink() {
      return ['services.current_accounts', 'services.investment_products', 'services.internal_transfers'].includes(this.page);
    },
    headerImages() {
      const defaultImage = new URL('@assets/images/header/web/general.jpg', import.meta.url).href;
      return {
        'vision': new URL('@assets/images/header/web/vision-and-mission.jpg', import.meta.url).href,
        'financial.index': new URL('@assets/images/header/web/financial-statements.jpg', import.meta.url).href,
        'career.index': new URL('@assets/images/header/web/vacancies.jpg', import.meta.url).href,
        'branch.index': new URL('@assets/images/header/web/service-points.jpg', import.meta.url).href,
        'services.current_accounts': new URL('@assets/images/header/web/current-account.jpg', import.meta.url).href,
        'privacy_policy': new URL('@assets/images/header/web/privacy-policy.jpg', import.meta.url).href,
        'internet_banking': new URL('@assets/images/header/web/internet-banking.jpg', import.meta.url).href,
        'services.internal_transfers': new URL('@assets/images/header/web/internal-transfers.jpg', import.meta.url).href,
        'services.individual_services': new URL('@assets/images/header/web/individual-service.jpg', import.meta.url).href,
        'services.investment_products': new URL('@assets/images/header/web/investment-accounts.jpg', import.meta.url).href,
        'telephone_banking_services' : new URL('@assets/images/header/web/telephone-banking-services.png', import.meta.url).href,
        'corporate_finance.accounts_management' : new URL('@assets/images/header/web/accounts-management.jpg', import.meta.url).href,
        'corporate_finance.agency_services': new URL('@assets/images/header/web/agency-services.jpg', import.meta.url).href,
        'corporate_finance.business_financing' : new URL('@assets/images/header/web/business-financing.jpg', import.meta.url).href,
        'default': defaultImage,
      };
    },
    mobileHeaderImages() {
      return {
        'vision': new URL('@assets/images/header/mobile/vision-and-mission.jpg', import.meta.url).href,
        'financial.index': new URL('@assets/images/header/mobile/financial-statements.jpg', import.meta.url).href,
        'career.index': new URL('@assets/images/header/mobile/vacancies.jpg', import.meta.url).href,
        'branch.index': new URL('@assets/images/header/mobile/service-points.jpg', import.meta.url).href,
        'services.current_accounts': new URL('@assets/images/header/mobile/current-account.jpg', import.meta.url).href,
        'privacy_policy.index': new URL('@assets/images/header/mobile/privacy-policy.jpg', import.meta.url).href,
        'internet_banking': new URL('@assets/images/header/mobile/internet-banking.jpg', import.meta.url).href,
        'services.internal_transfers': new URL('@assets/images/header/mobile/internal-transfers.jpg', import.meta.url).href,
        'services.individual_services': new URL('@assets/images/header/mobile/individual-service.jpg', import.meta.url).href,
        'services.investment_products': new URL('@assets/images/header/mobile/investment-accounts.jpg', import.meta.url).href,
        'telephone_banking_services' : new URL('@assets/images/header/mobile/telephone-banking-services.png', import.meta.url).href,
        'corporate_finance.accounts_management' : new URL('@assets/images/header/mobile/accounts-management.jpg', import.meta.url).href,
        'corporate_finance.business_financing' : new URL('@assets/images/header/mobile/business-financing.jpg', import.meta.url).href,
        'corporate_finance.agency_services': new URL('@assets/images/header/mobile/agency-services.jpg', import.meta.url).href,
        'default': new URL('@assets/images/header/mobile/general.jpg', import.meta.url).href,
      };
    },
    getHeaderImage() {
      const imageList = this.isMobile ? this.mobileHeaderImages : this.headerImages;
      return imageList[this.page] || imageList['default'];
    },
    isGeneralBackground() {
      return this.getHeaderImage === this.headerImages['default'];
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 640;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
