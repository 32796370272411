<template>
  <div
    class="w-full flex justify-center mt-10"
    @click="loadMore"
  >
    <button
      :disabled="!pagination.next"
      class="text-white w-52 h-10 rounded-2xl flex items-center justify-around relative"
      :class="pagination.next ? 'primary-bg-color' : 'bg-slate-400'"
    >
      {{ buttonText }}
      <img
        :class="locale == 'ar' ? '' : 'rotate-[270deg]'"  
        src="@assets/images/icons/left-arrow.svg"
        alt="arrow"
      >
      <img
        src="@assets/images/home/main-pattern.svg"
        alt="pattern"
        class="absolute w-20 top-0 opacity-60"
        :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
      >
    </button>
  </div>
</template>
  
  <script>
  import { useI18n } from 'vue-i18n';
  
  export default {
    props: {
      pagination: {
        type: Object,
        required: true,
      },
      loadMore: {
        type: Function,
        required: true,
      },
      buttonText: {
        type: String,
        required: true,
      },
    },
    setup() {
      const { locale } = useI18n();
      return { locale };
    },
  };
  </script>
  