<template>
  <!-- Wrap the button with the Link component and use dynamic href and text -->
  <Link :href="link">
    <button
      class="primary-bg-color mt-8 text-white md:w-52 w-40 h-12 rounded-2xl flex items-center justify-around relative overflow-hidden hover:w-60 transition-all duration-500 ease-in-out z-10"
    >
      {{ text }}
      <img
        src="@assets/images/icons/left-arrow.svg"
        :class="locale == 'ar' ? '' : 'rotate-180'"
        alt="logo"
      >
      <img
        src="@assets/images/home/main-pattern.svg"
        alt="logo"
        class="absolute w-20 top-0 opacity-60"
        :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
      >
    </button>
  </Link>
</template>

<script>
import { Link } from '@inertiajs/vue3'; // Import the Link component from Inertia.js
import { useI18n } from 'vue-i18n'; // Import useI18n to access locale

export default {
  components: {
    Link
  },
  props: {
    text: {
      type: String,
      required: true, // Button text
    },
    link: {
      type: String,
      required: true, // The URL to navigate to
    }
  },
  setup(props) {
    // Get the current locale
    const { locale } = useI18n();

    return {
      locale,
      props
    };
  }
};
</script>

<style scoped>
/* Add any necessary scoped styles here */
</style>
