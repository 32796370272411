<template>
  <button
    type="submit"
    :class="[
      isFormValid ? 'primary-bg-color text-white  hover:w-3/5' : 'bg-gray-400 text-gray-200',
      'w-1/2 py-3 px-4 rounded-2xl flex justify-between relative'
    ]"
    :disabled="!isFormValid"
  >
    {{ $t('home.contact_us.form.send_btn') }}
    <img
      :class="locale == 'ar' ? '' : 'rotate-180'"
      src="@assets/images/icons/left-arrow.svg"
      alt="logo"
    >
    <img
      src="@assets/images/home/main-pattern.svg"
      alt="logo"
      class="absolute w-20 top-0 opacity-60"
      :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
    >
  </button>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  props: {
    isFormValid: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    const { locale } = useI18n();

    return {
      locale
    }
  }
}
</script>
