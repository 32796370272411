<template>
  <div>
    <div class="w-screen primary-bg-color relative">
      <div
        class="min-h-[200px] px-5 md:ps-20 pb-20 gap-4 w-2/4 max-md:w-full flex flex-col justify-end text-white"
      >
        <img
          src="@assets/images/logos/footer-icon.svg"
          alt="logo"
          class="absolute left-0 bottom-0 w-96"
        >
      </div>
    </div>
    <div class="flex flex-col items-center justify-center my-10">
      <h1 class="primary-font text-3xl md:text-4xl font-bold mt-28">
        {{ $t(title) }}
      </h1>
      <p class="text-[#40444c] font-semibold mt-4 text-center w-2/5 max-sm:w-full max-sm:px-4">
        {{ $t(description) }}
      </p>
      <Link href="/">
        <button
          class="primary-bg-color mt-8 text-white md:w-52 w-40 h-12 rounded-2xl flex items-center justify-around relative  overflow-hidden hover:w-60 transition-all duration-500 ease-in-out z-10 mb"
        >
          {{ $t('error.back_to_home') }}
          <img
            src="@assets/images/home/main-pattern.svg"
            alt="logo"
            class="absolute w-20 top-0 opacity-60"
            :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
          >
        </button>
      </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Layout from '@WebsiteShared/MainLayout.vue';
import { computed } from 'vue';

export default {
  components: {
    Link,
  },
  layout: Layout,
  props: {
    status: {type: Number,default:0}
  },
  setup(props) {

    // Computed property for dynamic title based on status code
    const title = computed(() => {
      return {
        503: '503: Service Unavailable',
        500: 'error.500.title',
        404: 'error.404.title',
        403: '403: Forbidden',
      }[props.status]
    })

    // Computed property for dynamic description based on status code
    const description = computed(() => {
      return {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'error.500.description',
        404: 'error.404.description',
        403: 'Sorry, you are forbidden from accessing this page.',
      }[props.status]
    })

    return {
      title,
      description,
    }
  }
}
</script>

<style scoped></style>

