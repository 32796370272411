<template>
  <div class="">
    <!-- Render news data if available -->
    <div
      v-if="allNews.length"
      class="flex flex-col w-full py-20"
    >
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-items-center xl:grid-cols-4 w-11/12 mx-auto items-center gap-y-10 pb-20"
      >
        <div
          v-for="item in allNews"
          :key="item.id"
          class="w-full flex flex-col"
        >
          <NewsCard :content="item" />
        </div>
      </div>

      <!-- Load More Button -->
      <LoadMoreButton
        :pagination="pagination"
        :load-more="loadMore"
        :button-text="$t('news.show_more_btn')"
      />
    </div>

    <div v-else>
      <NoResult
        :title="$t('news.no_result.title')"
        :description="$t('news.no_result.description')"
      />
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import NewsCard from '@WebsiteShared/components/NewsCard.vue';
import NoResult from '@WebsiteShared/components/NoResult.vue';
import LoadMoreButton from '@components/LoadMoreButton.vue';
import { router } from '@inertiajs/vue3';

export default {
  components: {
    NewsCard,
    NoResult,
    LoadMoreButton,
  },
  layout: Layout,
  props: {
    news: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allNews: [...this.news.data],
      pagination: this.news.links,
      currentPage: this.news.meta.current_page,
    };
  },
  methods: {
    loadMore() {
      if (this.pagination.next) {
        // Parse the next page URL to get the page number
        const nextPageUrl = new URL(this.pagination.next);
        const nextPageNumber = nextPageUrl.searchParams.get('page');

        // Use router.get with the base URL and pass the page as a parameter, not in the URL
        router.post('/news', { page: nextPageNumber, }, {
          preserveScroll: true,
          replace: false,
          preserveState: true,
          onSuccess: (page) => {
            // Update the allNews and pagination with the new data
            this.allNews = [...this.allNews, ...page.props.news.data];
            this.pagination = page.props.news.links;
            this.currentPage = page.props.news.meta.current_page;
          },
        });
      }
    },
  },
};
</script>
