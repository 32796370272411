<!-- components/MobileNav.vue -->
<template>
  <nav>
    <ul>
      <li @click="toggleMobileNavbar">
        <div class="flex justify-between mt-10 relative z-10 ">
          <a
            class="px-3 py-2 text-md uppercase font-bold leading-snug primary-color hover:opacity-75"
            href="#"
          >
            <span class="ml-2 text-center">{{ title }}</span>
          </a>
          <img
            src="@assets/images/icons/arrow-down.svg"
            alt="logo"
          >
        </div>
      </li>
      <li
        v-if="isOpen"
      >
        <div
          v-for="(item,index) in navItem"
          :key="index"
        >
          <Link
            :href="item.link"
            class="block px-4 py-2 hover:font-bold text-[#40444c] z-10 relative"
            @click="handleLinkClick"
          >
            {{ $t(item.title) }}
          </Link>
        </div>
      </li>
    </ul>
  </nav>
</template>
  
  <script>
  import { Link } from '@inertiajs/vue3';

export default {
  components: {
    Link
  },
  props: {
    showMenu: {
      type: Boolean,
      required: true,
    },
    title: {
      type:String,
      default:""
    },
    navItem: {
      type:Array,
      default:()=>[],
    },
  },
  data(){
    return{
      isOpen:false
    }
  },
  methods:{
    toggleMobileNavbar(){
      this.isOpen=!this.isOpen;
    },
    //to close the movile nav after navigate to another page
    handleLinkClick() {
      this.$emit('close-menu');
    }
  }
  }
  </script>
  
  <style scoped>
  /* Add any mobile-specific styles here */
  </style>
  
