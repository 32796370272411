<template>
  <div>
    <div class="flex items-center justify-center">
      <!-- Render financials data if available -->
      <div
        v-if="allFinancials.length"
        class="container max-lg:px-10 px-20 my-20 max-sm:p-1"
      >
        <div class="border-2 rounded-3xl h-12 w-80 flex items-center px-4 mb-10 relative">
          <p class="text-[#96a0b6] whitespace-nowrap">
            {{ $t('financials_page.choose_year') }}
          </p>
          <div class="h-8 border-l-2 border-[#96a0b6] mx-2" />
          <div
            class="flex items-center justify-between w-44 h-full"
            @click="menu = !menu"
          >
            <p class="primary-color">
              {{ selectedYear || $t('financials_page.all') }}
            </p>
            <img
              src="@assets/images/icons/menu-arrow.svg"
              alt="menu-arrow"
            >
            <ul
              v-if="menu"
              data-popover="menu"
              data-popover-placement="bottom"
              class="absolute z-10 min-w-[180px] overflow-auto rounded-lg border border-slate-200 bg-white  shadow-lg shadow-sm focus:outline-none top-14 list-scroll"
            >
              <li
                class="bg-[#15438926] rounded-t-lg flex justify-center items-center h-12"
                @click="filterByYear(null)"
              >
                {{ $t('financials_page.all') }}
              </li>
              <li
                v-for="item in years"
                :key="item.id"
                role="menuitem"
                class="cursor-pointer text-slate-800 flex w-full text-sm items-center rounded-md p-3 transition-all hover:bg-[#15438926] focus:bg-slate-100 active:bg-slate-100 flex justify-center"
                @click="filterByYear(item.year)"
              >
                {{ item.year }}
              </li>
            </ul>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 place-items-center xl:grid-cols-3 w-full gap-y-10 pb-20">
          <div
            v-for="item in allFinancials"
            :key="item.id"
            class="w-full"
          >
            <FinancialsCard :content="item" />
          </div>
        </div>

        <!-- Load More Button -->
        <LoadMoreButton
          :pagination="pagination"
          :load-more="loadMore"
          :button-text="$t('financials_page.show_more')"
        />
      </div>

      <div v-else>
        <NoResult
          :title="$t('financials_page.no_result.title')"
          :description="$t('financials_page.no_result.description')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import FinancialsCard from './Cards/FinancialsCard.vue';
import NoResult from '@WebsiteShared/components/NoResult.vue';
import LoadMoreButton from '@components/LoadMoreButton.vue';
import { router } from '@inertiajs/vue3';

export default {
  components: {
    FinancialsCard,
    NoResult,
    LoadMoreButton,
  },
  layout: Layout,
  props: {
    financials: {
      type: Object,
      required: true,
    },
    years: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      allFinancials: [...this.financials.data], // Initialize with the first set of financial data
      pagination: this.financials.links,
      currentPage: this.financials.meta.current_page,
      menu: false,
      selectedYear: null
    };
  },
  //first way using query parameter in url
  // mounted() {
  //   // Check if there's a query parameter for year and set selectedYear
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const year = urlParams.get('year');

  //   if (year) {
  //     this.selectedYear = year;
  //   }
  // },
  //second way using query parameter in links
  mounted() {
    // Extract the 'year' query parameter from the links object
    const firstLink = this.financials.links.first;

    if (firstLink) {
      const urlParams = new URLSearchParams(firstLink.split('?')[1]);
      const year = urlParams.get('year');

      if (year) {
        this.selectedYear = year;
      }
    }

    console.log('Selected year from links:', this.selectedYear);
  },
  methods: {
    loadMore() {
      if (this.pagination.next) {
        // Parse the next page URL to get the page number
        const nextPageUrl = new URL(this.pagination.next);
        const nextPageNumber = nextPageUrl.searchParams.get('page');

        // Use router.post with the base URL and pass the page as a parameter, not in the URL
        router.post('/financials', { page: nextPageNumber }, {
          preserveScroll: true,
          replace: false,
          preserveState: true,
          onSuccess: (page) => {
            // Append new items to the existing allFinancials array
            this.allFinancials = [...this.allFinancials, ...page.props.financials.data];
            // Update pagination links and current page
            this.pagination = page.props.financials.links;
            this.currentPage = page.props.financials.meta.current_page;
          },
        });
      }
    },
    filterByYear(year) {
      this.selectedYear = year;
      router.post('/financials', { year: this.selectedYear }, {
        onSuccess: (res) => {
          this.allFinancials = res.props.financials.data;
          this.pagination = res.props.financials.links;
        },
      });
    }
  },

};
</script>
<style lang="scss" scoped></style>
