import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createApp, h } from 'vue';
import '../css/app.css';
import '../css/scss/main.scss';
import { i18n, setLocale } from './i18n'; // Import both i18n and setLocale

// Get Bugsnag API key from environment variables
const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;
const appVersion = import.meta.env.VITE_APP_VERSION;

const isProduction = import.meta.env.MODE === 'production';

if (bugsnagApiKey && isProduction) {
  // Initialize Bugsnag and Bugsnag Vue plugin
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    appVersion: appVersion,
    plugins: [new BugsnagPluginVue()],
  });
}


createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  title: title => title ? `${title}` : i18n.global.t('common.appName'),
  async setup({ el, App, props, plugin }) {
    // Set the initial locale and ensure messages are loaded before mounting the app
    await setLocale(i18n.global.locale); // Load the messages for the default locale ('en')

    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(i18n);

    // Install Bugsnag error handler for Vue
    const bugsnagVue = Bugsnag.getPlugin('vue');
    if (bugsnagVue) {
      bugsnagVue.installVueErrorHandler(app);
    }

    // Mount the app after ensuring that translations are properly loaded
    app.mount(el);
  },
});
