<template>
  <div class="relative min-h-44 mx-5">
    <div
      class="flex flex-col text-end py-5 items-center justify-between px-5 bg-[#f8f9fc] border-2 border-[#e2e6ee] min-h-full rounded-3xl absolute min-w-full"
    >
      <img
        class="w-6"
        src="@assets/images/icons/file.png"
        alt=""
      >
      <h1 class="text-[#284478] text-xl">
        {{ content.year }}
      </h1>

      <a
        :href="content.file"
        :download="content.file"
        target="_blank"
        class="min-w-full min-h-10 rounded-lg flex items-center justify-between px-3 bg-white"
      >
        <p class="text-[#626c83] text-lg whitespace-nowrap">{{ content.title }}</p>
        <img
          class="w-6"
          src="@assets/images/icons/arrow-down.png"
          alt=""
        >
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Array,
      default: () => [],
    }
  }
}
</script>