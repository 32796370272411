<template>
  <div class="flex items-center justify-center absolute top-0 w-full">
    <nav
      v-if="!showMenu"
      class="flex flex-wrap items-center justify-between px-2 py-3 bg-white max-lg:bg-transparent mb-3 mt-10 w-11/12 rounded-xl z-10"
    >
      <div class="container px-4 mx-auto flex items-center justify-between lg:justify-evenly ">
        <div class="flex items-center gap-4">
          <Link href="/">
            <img
              src="@assets/images/logos/Navlogo.svg"
              alt="logo"
            >
          </Link>
          <div
            class="relative flex justify-between max-lg:w-auto px-4 lg:static lg:block lg:justify-start"
          >
            <LocalLan />
          </div>
        </div>
        <div class="flex items-center max-lg:hidden">
          <ul class="flex flex-col lg:flex-row list-none ml-auto items-center uppercase">
            <li class="nav-item relative">
              <Link
                href="/"
                :class="[{ 'font-bold text-primary primary-color activeHome': isActive('/') }, 'block px-4 py-2 font-bold hover:opacity-75']"
              >
                <i class="fab fa-pinterest text-lg leading-lg text-white opacity-75" />
                <span class="ml-2 text-center">{{ $t('navbar.home') }}</span>
              </Link>
            </li>
            <DropdownMenu
              :title="$t('navbar.about')"
              :menu-items="aboutMenuItems"
            />
            <!-- <DropdownMenu :title="$t('navbar.shariah_board')" :menuItems="shariah_boardMenuItems"/> -->
            <li class="nav-item relative">
              <Link
                href="/sharia-compliance"
                :class="[{ 'font-bold  primary-color  activeHome': isActive('/sharia-compliance') }, 'block px-4 py-2 font-bold hover:opacity-75']"
              >
                <i class="fab fa-pinterest text-lg leading-lg text-white opacity-75" />
                <span class="ml-2 text-center">{{ $t('navbar.Sharia_Compliance') }}</span>
              </Link>
            </li>
            <DropdownMenu
              :title="$t('navbar.services_people')"
              :menu-items="servicesPeopleMenuItems"
            />
            <DropdownMenu
              :title="$t('navbar.corprate_banking')"
              :menu-items="corporateMenuItems"
            />
            <!-- <DropdownMenu
              :title="$t('navbar.banking_srvices')"
              :menuItems="bankingServicesMenuItems"
            /> -->
            <li class="nav-item relative">
              <Link
                href="/banking/telephone-banking-services"
                :class="[{ 'font-bold  primary-color activeHome': isActive('/banking/telephone-banking-services') }, 'block px-4 py-2 font-bold hover:opacity-75']"
              >
                <i class="fab fa-pinterest text-lg leading-lg text-white opacity-75" />
                <span class="ml-2 text-center">{{ $t('navbar.telephone_banking_services') }}</span>
              </Link>
            </li>
            <li class="nav-item relative">
              <Link 
                href="/internet-banking"
                :class="[{ 'font-bold  primary-color activeHome': isActive('/internet-banking') }, 'block px-4 py-2 font-bold hover:opacity-75']"
              >
                <i class="fab fa-pinterest text-lg leading-lg text-white opacity-75" />
                <span class="ml-2 text-center">{{ $t('navbar.internet_banking') }}</span>
              </Link>
            </li>
            <DropdownMenu
              :title="$t('navbar.jobs')"
              :menu-items="jobsMenuItems"
              :is-public-id-job="props.public_career_id"
            />
          </ul>
        </div>
        <div class="lg:hidden">
          <img
            src="@assets/images/icons/menu.svg"
            alt="menu"
            class=" cursor-pointer"
            @click="toggleNavbar"
          >
        </div>
      </div>
    </nav>
    <div
      v-if="showMenu"
      class="w-full h-screen bg-[#edf9ff] mobile-nav-index fixed inset-0 overflow-scroll uppercase"
    >
      <div class="flex flex-col justify-center justify-items-center p-10 relative">
        <div class="w-full">
          <img
            src="@assets/images/icons/cancel.svg"
            alt="menu"
            @click="toggleNavbar"
          >
        </div>
        <div class="flex justify-center items-center mt-10">
          <img
            src="@assets/images/logos/mobileNavLogo.svg"
            alt="logo"
          >
        </div>
        <div class="flex flex-col">
          <div class="flex justify-between mt-10">
            <Link
              href="/"
              class="px-3 py-2 flex items-center text-md uppercase font-bold leading-snug primary-color hover:opacity-75"
              @click="handleCloseMenu"
            >
              <span class="ml-2 text-center">{{ $t('navbar.home') }}</span>
            </Link>
          </div>
          <MobileNav
            :show-menu="showMenu"
            :title="$t('navbar.about')"
            :nav-item="aboutMenuItems"
            @close-menu="handleCloseMenu"
          />
          <!-- <MobileNav
            :showMenu="showMenu"
            :title="$t('navbar.shariah_board')"
            :navItem="shariah_boardMenuItems"
            @closeMenu="handleCloseMenu"
          /> -->
          <div class="flex justify-between mt-10">
            <Link
              href="/sharia-compliance"
              class="px-3 py-2 text-md uppercase font-bold leading-snug primary-color hover:opacity-75 cursor-pointer  z-10 "
              @click="handleCloseMenu"
            >
              <span class="ml-2 text-center">{{ $t('navbar.Sharia_Compliance') }}</span>
            </Link>
          </div>
          <MobileNav
            :show-menu="showMenu"
            :title="$t('navbar.services_people')"
            :nav-item="servicesPeopleMenuItems"
            @close-menu="handleCloseMenu"
          />
          <MobileNav
            :show-menu="showMenu"
            :title="$t('navbar.corprate_banking')"
            :nav-item="corporateMenuItems"
            @close-menu="handleCloseMenu"
          />
          <!-- <MobileNav
            :showMenu="showMenu"
            :title="$t('navbar.banking_srvices')"
            :navItem="bankingServicesMenuItems"
            @closeMenu="handleCloseMenu"
          /> -->
          <div class="flex justify-between mt-10">
            <Link
              href="/banking/telephone-banking-services"
              class="px-3 py-2 text-md uppercase font-bold leading-snug primary-color hover:opacity-75 cursor-pointer  z-10 "
              @click="handleCloseMenu"
            >
              <span class="ml-2 text-center">{{ $t('navbar.telephone_banking_services') }}</span>
            </Link>
          </div>
          <div class="flex justify-between mt-10">
            <Link
              href="/internet-banking"
              class="px-3 py-2 text-md uppercase font-bold leading-snug primary-color hover:opacity-75 cursor-pointer  z-10 "
              @click="handleCloseMenu"
            >
              <span class="ml-2 text-center">{{ $t('navbar.internet_banking') }}</span>
            </Link>
          </div>
          <MobileNav
            :show-menu="showMenu"
            :title="$t('navbar.jobs')"
            :nav-item="jobsMenuItems"
            @close-menu="handleCloseMenu"
          />
        </div>
        <div class="flex items-center justify-center mt-32">
          <SocialMediaLinks color="default" />
        </div>
        <p
          class="text-sm text-center primary-color mt-4"
        >
          {{ $t('navbar.text_bank') }}
        </p>
        <img
          src="@assets/images/logos/mobileNavbg.svg"
          alt="bg-logo"
          class="absolute bottom-0 left-0 z-0"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import SocialMediaLinks from '@components/SocialMediaLinks.vue';
import { Link, usePage } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue';
import DropdownMenu from './DropdownMenu.vue';
import LocalLan from './LocaleDiv.vue';
import MobileNav from './MobileNav.vue';

// const { t } = useI18n();
//check the active page 
const page = usePage();
function isActive(link) {
  return page.url === link; // Compares the current URL with the link
}
const { props } = usePage();

// Define the dropdown menu items with title, link, and link_open
const aboutMenuItems = [
  { title: 'navbar.our_vision', link: '/about/vision', link_open: 'inside' },
  { title: 'navbar.Statement_of_Compliance', link: '/compliance-statement', link_open: 'inside' },
  // { title: 'navbar.Board_of_Directors', link: '/about/board', link_open: 'inside' },
  // { title: 'navbar.Executive_Management', link: '/about/executive-management', link_open: 'inside' },
  // { title: 'navbar.Sharia_Council', link: '/about/sharia-council', link_open: 'inside' },
  // { title: 'navbar.Anti_Money_Laundering', link: '/about/anti-money-laundering', link_open: 'inside' },
  // { title: 'navbar.Financial_statements', link: '/financials', link_open: 'inside' },
];
// const shariah_boardMenuItems = [
//     { title: 'navbar.Sharia_Compliance', link: '/sharia-compliance', link_open: 'inside' },
//     // { title: 'navbar.Members-of-the-Board', link: '/shariah-board', link_open: 'inside' },
// ]
const servicesPeopleMenuItems = [
  { title: 'navbar.open_account', link: '/services/individual-services', link_open: 'inside' },
  { title: 'navbar.Current_accounts', link: '/services/current-accounts', link_open: 'inside' },
  // { title: 'navbar.Savings_accounts', link: '/services/savings-accounts', link_open: 'inside' },
  { title: 'navbar.Investment_Products', link: '/services/investment-products', link_open: 'inside' },
  // { title: 'navbar.Personal_transfers', link: '/services/personal-transfers', link_open: 'inside' },
  { title: 'navbar.Internal_transfers', link: '/services/internal-transfers', link_open: 'inside' },
  // { title: 'navbar.Issuance_of_electronic_payment_cards', link: '/services/electronic-payment-cards', link_open: 'inside' },
];

const corporateMenuItems = [
  { title: 'navbar.business_financing', link: '/corporate/business-financing', link_open: 'inside' },
  { title: 'navbar.accounts_management', link: '/corporate/accounts-management', link_open: 'inside' },
  { title: 'navbar.Agency_Services', link: '/corporate/agency-services', link_open: 'inside' },
  { title: 'navbar.opening_accounts', link: '/corporate/opening-accounts', link_open: 'inside' },
];

// const bankingServicesMenuItems = [
//   { title: 'navbar.login_to_services', link: 'https://mobile.hadhramoutbank.com/IBS/', link_open: 'outside' }, // external link
//   { title: 'navbar.accounts_management', link: '/banking/manage-accounts', link_open: 'inside' },
//   { title: 'navbar.money_transfers', link: '/banking/money-transfers', link_open: 'inside' },
//   { title: 'navbar.services_banking_by_sms', link: '/banking/sms-services', link_open: 'inside' },
//   { title: 'navbar.Bill_payment', link: '/banking/bill-payment', link_open: 'inside' },
// ];

// const telephoneBankingServicesItem = {title: 'navbar.telephone_banking_services', link: '/banking/telephone-banking-services', link_open: 'inside'};
const menuItems = {
  employmentForm: {
    title: 'navbar.employment_Form',
    link: `/careers/${props.public_career_id}`,
    link_open: 'inside',
  },
  careers: {
    title: 'navbar.vacancies',
    link: `/careers`,
    link_open: 'inside',
  },
};
const jobsMenuItems = [
  ...(props.public_career_id!==null ? [menuItems.employmentForm] : []),
  menuItems.careers,
];
// const errorStatuses = [404, 500, 403, 503];
// const social_media_links = errorStatuses.includes(props.status) 
//     ? [] 
//     : props.settings?.general?.social_media_links || [];

// const mediaImg = computed(() => ({
//     facebook: new URL('@assets/images/icons/media/navbar/facebook.svg', import.meta.url).href,
//     twitter: new URL('@assets/images/icons/media/navbar/twitter.svg', import.meta.url).href,
//     instagram: new URL('@assets/images/icons/media/navbar/instagram.svg', import.meta.url).href,
//     youtube: new URL('@assets/images/icons/media/navbar/youtube.svg', import.meta.url).href,
//     linkedin: new URL('@assets/images/icons/media/navbar/linkedin.svg', import.meta.url).href,
// }));

// Menu state for mobile navigation
const showMenu = ref(false);
// const isOpen=ref(false)
function toggleNavbar() {
  showMenu.value = !showMenu.value;
}
// function toggleMobileNavbar(){
//   isOpen.value=!isOpen.value;
// }
function handleCloseMenu() {
  showMenu.value = false; // This will close the mobile menu
}
onMounted(()=>{
  console.log(props.public_career_id,'nav')
})
</script>

<style scoped>
.activeHome::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 8px;
  width: 94%; 
  height: 10px; 
  border-radius: 20px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: #daab00;
  transition: all 0.3s ease-in-out;
}
.mobile-nav-index{
  z-index: 1400;
}
</style>
