<template>
  <div>
    <div class="flex flex-col justify-center items-center my-20">
      <img
        class="max-md:w-24"
        src="@assets/images/logos/aboutLogo.svg"
        alt="logo"
      >
      <div class="mt-10 flex flex-col items-center">
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="w-[7rem]"
        >
        <h1 class="primary-font text-3xl md:text-4xl font-bold mt-3 mb-10">
          {{ $t('agency_services.title') }}
        </h1>
      </div>
      <p class="secondary-font w-9/12 max-sm:w-11/12 px-1 leading-7">
        {{ $t('agency_services.description') }}
      </p>
    </div>
  </div>
</template>
<script>
import Layout from '@WebsiteShared/MainLayout.vue';
export default {
  layout: Layout,
}
</script>
<style lang="scss" scoped></style>