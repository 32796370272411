<template>
  <div
    class="px-10 flex flex-row  max-h-[80vh] -translate-y overflow-y-hidden  first-line:pb-5   card-container max-md:w-[100vw]"
    @mouseenter="disableMapScroll"
    @mouseleave="enableMapScroll"
  >
    <div
      v-if="isListOpen"
      class="w-[380px] overflow-y-scroll scrollbar-hidden relative max-md:w-full max-md:px-2"
      :class="screenWidth > 770 ? 'max-h-[55vh]' : 'max-h-[35vh] pb-4 '"
      @click.stop
    >
      <!-- Branches List with Result Count -->
      <Transition name="slide-fade">
        <div
          class="branch-list bg-white shadow-md p-2 gap-4 rounded-xl pb-5 pt-10 pr-1 relative flex flex-col justify-center items-center max-md:rounded-t-none"
        >
          <!-- Results Count at the Top -->
          <div
            class="text-right font-semibold text-blue-900 mb-2 px-6 w-full text-lg"
          >
            {{ $t('branch.list.results_count') }}: {{ branches.length }}
          </div>

          <!-- No Results Message -->
          <div
            v-if="branches.length === 0"
            class="text-center text-gray-500 font-semibold py-10"
          >
            {{ $t('branch.list.no_results') }}
          </div>

          <!-- Branch Cards -->
          <div v-else>
            <div
              v-for="branch in branches"
              :key="branch.id"
              class="branch-list-item w-full mb-2"
            >
              <BranchListCard
                :content="branch"
                class="w-full"
                @select-branch="centerMapOnBranch"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <!-- Toggle Button Positioned Absolutely Along the Top Edge -->
    <button
      class="bg-blue-900 h-16 text-white p-3 flex items-center rounded-lg transition-all duration-300 max-md:hidden"
      @click="toggleList"
    >
      <img
        src="@assets/images/icons/arrow-right.svg"
        :class="['mr-2 transition-transform', { 'rotate-180': isListOpen }]"
        alt="arrow icon"
      >
    </button>
    <!-- Toggle Button Positioned Absolutely Along the Top Edge in Mobile -->
    <button
      class="bg-blue-900 h-12 text-white p-2 flex items-center rounded-lg transition-all duration-300 block md:hidden absolute  "
      :class="['-bottom-9 left-12', { '-bottom-7 left-12': isListOpen }]"
      @click="toggleList"
    >
      <img
        src="@assets/images/icons/arrow-down-mobile.svg"
        :class="[' transition-transform', { 'rotate-180': isListOpen }]"
        alt="arrow icon"
      >
    </button>
  </div>
</template>


<script>
import BranchListCard from './Cards/BranchListCard.vue';

export default {
  components: { BranchListCard },
  props: {
    branches: {
      type: Array,
      required: true,
    },
    isListOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['toggle-list', 'disable-map-scroll', 'enable-map-scroll', 'center-map','update:screen-width'],
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    isRtl() {
      return document.documentElement.getAttribute('dir') === 'rtl';
    },
  },
   mounted() {
    window.addEventListener('resize', this.updateScreenWidth);
     this.updateScreenWidth();
  },
   beforeUnmount() {
   window.removeEventListener('resize', this.updateScreenWidth);
  },
  methods: {
    toggleList() {
      this.$emit('toggle-list');
    },
    disableMapScroll() {
      this.$emit('disable-map-scroll');
    },
    enableMapScroll() {
      this.$emit('enable-map-scroll');
    },
    centerMapOnBranch(branch) {
      this.$emit('center-map', branch);
    },
    //check the screen size to show this list if the button is false
   updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      this.$emit('update:screen-width', this.screenWidth);
    },
  },
};
</script>
<style scoped>
/* Custom class to hide scrollbars */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.card-container {
  right: 30px;
  left: 40px;
}
@media screen and (max-width: 770px) {
  .card-container {
    margin-top: 345px;
  }
}
</style>
