<template>
  <div
    v-if="showTermsDialog"
    class="relative z-10"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    />
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
        <div
          class="relative transform overflow-hidden rounded-2xl bg-white shadow-xl transition-all sm:my-8 w-2/4 max-lg:w-3/4 max-sm:w-full"
        >
          <div class="bg-[#edf9ff] px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col">
            <div class="flex items-center justify-between">
              <div />
              <img
                src="@assets/images/icons/cancel-dialog.svg"
                alt="cancel"
                class="cursor-pointer"
                @click="closeDialog"
              >
            </div>
            <h1 class="primary-color text-2xl">
              {{ $t('careers.terms_and_conditions.title') }}
            </h1>
            <ol
              type="1"
              class="mt-10 primary-color font-bold list-decimal list-inside"
            >
              <li>{{ $t('careers.terms_and_conditions.content.application_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.application_part1') }}</li>
                <li>{{ $t('careers.terms_and_conditions.content.application_part2') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.review_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.review_part1') }}</li>
                <li>{{ $t('careers.terms_and_conditions.content.review_part2') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.confidentiality_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.confidentiality_part1') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.employment_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.employment_part1') }}</li>
                <li>{{ $t('careers.terms_and_conditions.content.employment_part2') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.diversity_inclusion_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.diversity_inclusion_part1') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.changes_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.changes_part1') }}</li>
              </ul>
              <li>{{ $t('careers.terms_and_conditions.content.consent_title') }}</li>
              <ul
                class="text-md min-text-sm mx-14 list-disc marker:text-[#284478] leading-8 text-[#40444c] font-light"
              >
                <li>{{ $t('careers.terms_and_conditions.content.consent_part1') }}</li>
              </ul>
            </ol>
            <p class="text-[#40444c] text-md font-bold mt-10">
              {{ $t('careers.terms_and_conditions.content.note_title') }}
            </p>
            <p class="text-[#40444c] mt-2 text-lg mb-4">
              {{ $t('careers.terms_and_conditions.content.note_part1') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showTermsDialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      console.log('closed');
      
      this.$emit('update:showTermsDialog', false);  // Emit the update event to close the dialog
    },
  },
};
</script>