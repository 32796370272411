<template>
  <div
    class="bg-gradient-to-r primary-bg-color text-white rounded-2xl p-6 flex flex-col w-[250px] sm:w-[340px]"
    @click="$emit('select-branch', content)"
  >
    <!-- Logo Section -->
    <div class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
      <img
        src="@assets/images/logos/aboutLogo.svg"
        alt="Logo"
        class="w-10 h-10"
      >
    </div>

    <!-- Branch Type Badge -->
    <div>
      <p
        :class="[
          'rounded-lg px-3 py-1 text-sm  mt-2 text-center whitespace-nowrap inline-block',
          `bg-${content.type.tailwindColor}-400 text-${content.type.tailwindColor}-900`
        ]"
      >
        {{ content.type.label }}
      </p>
    </div>

    <!-- Branch Name -->
    <h2 class="text-xl font-bold mt-4">
      {{ content.name }}
    </h2>

    <!-- Address -->
    <p class="text-[16px] text-gray-200 mt-2">
      {{ content.address }}
    </p>

    <!-- Timings and Contact -->
    <div class="flex justify-between items-center w-full mt-6 max-md:flex-col gap-2">
      <!-- Phone Number -->
      <div class="flex items-center gap-1 text-lg">
        <img
          src="@assets/images/icons/number.svg"
          alt="phone"
        >
        <span class="text-sm">{{ content.phone }}</span>
      </div>
      <!-- Timing -->
      <div class="flex items-center gap-1 text-lg">
        <img
          src="@assets/images/icons/clock.svg"
          alt="phone"
        >
        <span class="text-sm whitespace-nowrap">
          <!-- Display timing or "24 hours" if it's open 24/7 -->
          {{ content.is_24_hours ? '24 Hours' : `${content.open_time} - ${content.close_time}` }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    content: {
      type: Object,
      required: true
    },
  },
  emits: ['select-branch'],
}
</script>

