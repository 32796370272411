<template>
  <div class="mt-16">
    <h1 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
      {{ $t(`${content.file_name}.required_documents.title`) }}
    </h1>
    <div class="flex justify-center items-center flex-wrap mt-6 leading-7">
      <div
        v-for="(item, index) in data"
        :key="item.id"
        class="w-30rem border-2 rounded-3xl flex flex-col px-5 py-8 mx-5 mt-5"
        :class="(index % 4 === 0 || index % 4 === 3) ? 'bg-[#284478]' : 'bg-[#edf9ff]'"
      >
        <img
          :src="item.img"
          alt="customer-icon"
          class="size-10"
        >
        <p
          class="text-3xl font-bold py-6"
          :class="(index % 4 === 0 || index % 4 === 3) ? 'text-white' : 'text-[#284478]'"
        >
          {{ $t(item.title) }}
        </p>
        <ul
          class="h-28 list-disc px-5 text-xl font-normal"
          :class="(index % 4 === 0 || index % 4 === 3) ? 'text-[#edf9ff]' : 'text-[#284478]'"
        >
          <li
            v-for="line in item.description"
            :key="line"
          >
            {{ $t(line) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type:Object,
      default: ()=>({}),
    },
    data: {
      type:Array,
      default: ()=>[],
    },
  },
};
</script>
