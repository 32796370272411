<template>
  <div class="min-h-[80dvh] flex flex-col gap-10 text-center items-center justify-center">
    <h1 class="primary-font">
      {{ title }}
    </h1>
    <p
      style="white-space: pre-line"
      class="w-2/4 max-md:w-full secondary-font px-10"
    >
      {{ description }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>


<style>
</style>
