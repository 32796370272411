import { createI18n } from 'vue-i18n';

// Function to load locale messages dynamically
async function loadLocaleMessages(locale) {
  const files = [
    { name: 'accounts_management', loader: import(`./lang/${locale}/accounts_management.json`) },
    { name: 'agency_services', loader: import(`./lang/${locale}/agency_services.json`) },
    { name: 'branch', loader: import(`./lang/${locale}/branch.json`) },
    { name: 'business_financing', loader: import(`./lang/${locale}/business_financing.json`) },
    { name: 'careers', loader: import(`./lang/${locale}/careers.json`) },
    { name: 'charitable_associations_and_institutions', loader: import(`./lang/${locale}/charitable_associations_and_institutions.json`) },
    { name: 'common', loader: import(`./lang/${locale}/common.json`) },
    { name: 'compliance_statement', loader: import(`./lang/${locale}/compliance_statement.json`) },
    { name: 'current_accounts', loader: import(`./lang/${locale}/current_accounts.json`) },
    { name: 'error', loader: import(`./lang/${locale}/error.json`) },
    { name: 'financials_page', loader: import(`./lang/${locale}/financials_page.json`) },
    { name: 'footer', loader: import(`./lang/${locale}/footer.json`) },
    { name: 'home', loader: import(`./lang/${locale}/home.json`) },
    { name: 'individual_services', loader: import(`./lang/${locale}/individual_services.json`) },
    { name: 'internal_transfers', loader: import(`./lang/${locale}/internal_transfers.json`) },
    {name: 'internet_banking', loader: import(`./lang/${locale}/internet_banking.json`) },
    { name: 'investment_products', loader: import(`./lang/${locale}/investment_products.json`) },
    { name: 'navbar', loader: import(`./lang/${locale}/navbar.json`) },
    { name: 'news', loader: import(`./lang/${locale}/news.json`) },
    { name: 'our_vision', loader: import(`./lang/${locale}/our_vision.json`) },
    { name: 'page_headers', loader: import(`./lang/${locale}/page_headers.json`) },
    { name: 'privacy_policy', loader: import(`./lang/${locale}/privacy_policy.json`) },
    { name: 'seo', loader: import(`./lang/${locale}/seo.json`) },
    { name: 'sharia_compliance', loader: import(`./lang/${locale}/sharia_compliance.json`) },
    { name: 'telephone_banking_services', loader: import(`./lang/${locale}/telephone_banking_services.json`) },
    { name: 'terms_conditions', loader: import(`./lang/${locale}/terms_conditions.json`) },
  ];

  try {
    const messages = await Promise.all(
      files.map(async ({ name, loader }) => {
        try {
          const data = await loader;
          return { [name]: data.default };
        } catch (error) {
          console.warn(`Could not load ${name} for locale: ${locale}. Falling back to default/fallback.`);
          return {}; // Return an empty object if loading fails
        }
      })
    );

    return messages.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  } catch (error) {
    console.error(`Could not load messages for locale: ${locale}`, error);
    return {};
  }
}

// Initialize vue-i18n with an empty messages object
export const i18n = createI18n({
  locale: 'ar', // Default locale (Arabic)
  fallbackLocale: 'en', // Fallback to English if the locale cannot be determined
  messages: {}, // Initially, messages are empty to enable lazy loading
});

// Function to get the direction based on locale (rtl for Arabic, ltr for English)
export const getDirection = (locale) => {
  const rtlLocales = ['ar']; // List of RTL locales
  return rtlLocales.includes(locale) ? 'rtl' : 'ltr';
};

// Function to set the direction of the document
export const setDirection = (locale) => {
  const direction = getDirection(locale);
  document.documentElement.setAttribute('dir', direction); // Set the `dir` attribute
};

// Function to change the locale dynamically
export const setLocale = async (locale) => {
  if (!locale) {
    console.error('Locale is not defined or is invalid.');
    return;
  }
  
  try {
    const messages = await loadLocaleMessages(locale);
    if (Object.keys(messages).length === 0) {
      console.warn(`Locale ${locale} could not load properly, falling back to 'en'.`);
      locale = 'en';
      setDirection(locale);
    }

    const fallbackMessages = locale === 'en' ? messages : await loadLocaleMessages('en');
    i18n.global.setLocaleMessage(locale, messages);
    i18n.global.setLocaleMessage('en', fallbackMessages); // Set fallback locale messages
    
    i18n.global.locale = locale;
    setDirection(locale);

  } catch (error) {
    console.error(`Failed to load locale: ${locale}, switching to fallback locale 'en'.`, error);
    await setLocale('en'); // Ensure that 'en' is set in case of any error
  }
};
