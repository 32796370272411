<template>
  <div class="flex flex-col justify-center items-center my-20">
    <div
      v-for="item in data"
      :key="item.id"
      class="flex flex-col justify-center items-center my-5 w-full"
    >
      <div
        class="w-4/5 h-auto border-2 rounded-3xl flex flex-col justify-center p-10"
        :class="item.id%2==0?'bg-[#284478]':'bg-[#edf9ff]'"
      >
        <h1 :class="item.id%2==0?'text-[#edf9ff]':'primary-color'">
          {{ $t(item.title) }}
        </h1>
        <ul
          v-if="item.id===3"
          class="pt-10 pb-6 max-sm:pb-0 w-3/4 max-md:w-full gray-color text-xl leading-normal"
        >
          <li>{{ $t('our_vision.goal_one') }}</li>
          <li>{{ $t('our_vision.goal_two') }}</li>
          <li>{{ $t('our_vision.goal_three') }}</li>
          <li>{{ $t('our_vision.goal_four') }}</li>
        </ul>
        <p
          v-else
          class="pt-10 pb-6 max-sm:pb-0 w-3/4 max-md:w-full leading-normal"
          :class="item.id%2==0?'text-[#edf9ff]':'gray-color'"
        >
          {{ $t(item.description) }}
        </p>
      </div>
    </div>
    <img
      src="@assets/images/home/main-pattern.svg"
      alt="pattern"
      class="mt-8"
    >
    <h1 class="primary-color mt-5">
      {{ $t('our_vision.our_values') }}
    </h1>
    <div class="flex items-center mt-10 w-full justify-center flex-wrap">
      <div
        v-for="item in valuesData "
        :key="item.id"
        class="w-60 h-auto border-2 rounded-3xl flex flex-col p-5 mx-5 mt-5 max-sm:w-64"
        :class="item.id%2==0?'bg-[#284478] mt-28 max-sm:mt-4 max-sm:mr-44':'bg-[#edf9ff]'"
      >
        <img
          :src="item.url"
          alt="pattern"
          class="image"
        >
        <h1
          class="text-2xl mt-4 mb-12 max-sm:mb-2"
          :class="item.id%2==0?'text-[#edf9ff]':'primary-color'"
        >
          {{ $t(item.title) }}
        </h1>
      </div>
    </div>
  </div>
</template>
<script>
  import Layout from '@WebsiteShared/MainLayout.vue';
import { useI18n } from 'vue-i18n';
  export default {
    layout: Layout,
    setup() {
      const { t } = useI18n();
      const data = [
        { id: 1, title: 'our_vision.vision', description: 'our_vision.vision_description'},
        { id: 2, title: 'our_vision.our_message', description: 'our_vision.our_message_description'},
        { id: 3, title: 'our_vision.our_goal', description: 'our_vision.our_message_description'}
      ];
        const valuesData = [
        { id: 1, title: 'our_vision.work', url:"/assets/images/aboutUS/work.svg" },
        { id: 2, title: 'our_vision.customer', url:"/assets/images/aboutUS/icon2.svg"},
        { id: 3, title: 'our_vision.flexibility', url:"/assets/images/aboutUS/icon3.svg" },
        { id: 4, title: 'our_vision.responsibility', url:"/assets/images/aboutUS/icon4.svg" },
        { id: 5, title: 'our_vision.innovation', url:"/assets/images/aboutUS/icon5.svg" },
      ];
      
      return { t, data , valuesData};
    },
  }
</script>
  <style lang="scss" scoped>
  ul{
    list-style:none;
  }
li::before {content: "•"; color: #daab00;
  display: inline-block; width: 2em;
  margin-left: -1em
  }
  .image{
    width: 52px;
  }
  </style>
  