<template>
  <div class="container text-[#626c83] mx-auto py-20 font-bold">
    <!-- Features Section -->
    <FeaturesSection
      :content="{file_name: 'investment_products'}"
      :features="features"
    />

    <!-- Required Documents Section -->
    <RequiredDocuments
      :content="{file_name: 'investment_products'}"
      :data="data"
    />

    <!-- Types of Investment Products Section -->
    <TypesOfInvestmentProducts :type-keys="typeKeys" />

    <!-- FAQ Section -->
    <FAQSection
      :content="{file_name: 'investment_products'}"
      :faqs="faqs"
      :toggle-answer="toggleAnswer"
    />
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue'
import FAQSection from '@components/Services/FAQSection.vue'
import FeaturesSection from '@components/Services/FeaturesSection.vue'
import RequiredDocuments from '@components/Services/RequiredDocuments.vue'
import TypesOfInvestmentProducts from '@components/Services/TypesOfInvestmentProducts.vue'

import { computed, ref } from 'vue'

export default {
  components: {
    FeaturesSection,
    RequiredDocuments,
    TypesOfInvestmentProducts,
    FAQSection,
  },
  layout: Layout,
  setup() {
    const features = Array.from({ length: 10 }, (_, i) => `feature${i + 1}`)
    const typeKeys = Array.from({ length: 3 }, (_, i) => `type${i + 1}`)

    const data = computed(() => [
      {
        id: 1,
        title: 'investment_products.required_documents.new_customers.title',
        description: [
          'investment_products.required_documents.new_customers.account_agreement',
          'investment_products.required_documents.new_customers.proof_identity',
          'investment_products.required_documents.new_customers.proof_address',
        ],
        img: new URL('@assets/images/services/new-customer.svg', import.meta.url).href,
      },
      {
        id: 2,
        title: 'investment_products.required_documents.existing_customers.title',
        description: ['investment_products.required_documents.existing_customers.description'],
        img: new URL('@assets/images/services/existing-customer.svg', import.meta.url).href,
      },
    ])

    const faqs = ref([
      { showAnswer: false },
      { showAnswer: false },
      { showAnswer: false },
    ])

    const toggleAnswer = (index) => {
      faqs.value[index].showAnswer = !faqs.value[index].showAnswer
    }

    return { data, features, typeKeys, faqs, toggleAnswer }
  },
}
</script>
