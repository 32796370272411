<template>
  <form
    class="bg-white w-full flex flex-col gap-7 rounded-[3rem] z-30 p-10"
    @submit.prevent="submit"
  >
    <div class="flex max-md:flex-col gap-2">
      <div class="w-1/2 max-md:w-full flex flex-col gap-2">
        <p class="primary-color">
          {{ $t('home.contact_us.form.first_name') }}
          <span class="text-red-500">*</span>
        </p>
        <input
          v-model="form.first_name"
          :placeholder="$t('home.contact_us.form.first_name_placeholder')"
          class="rounded-2xl ps-2 border w-full h-12"
          type="text"
        >
        <span
          v-if="form.errors.first_name"
          class="text-red-500"
        >{{ form.errors.first_name }}</span>
      </div>
      <div class="w-1/2 max-md:w-full flex flex-col gap-2">
        <p class="primary-color">
          {{ $t('home.contact_us.form.last_name') }}
          <span class="text-red-500">*</span>
        </p>
        <input
          v-model="form.last_name"
          :placeholder="$t('home.contact_us.form.last_name_placeholder')"
          class="rounded-2xl ps-2 border w-full h-12"
          type="text"
        >
        <span
          v-if="form.errors.last_name"
          class="text-red-500"
        >{{ form.errors.last_name }}</span>
      </div>
    </div>
    <div class="flex flex-col gap-2">         
      <p class="primary-color">
        {{ $t('home.contact_us.form.email') }}
        <span class="text-red-500">*</span>
      </p>     
      <input
        v-model="form.email"
        :placeholder="$t('home.contact_us.form.email_placeholder')"
        class="rounded-2xl ps-2 border w-full h-12"
        type="email"
      >
      <span
        v-if="form.errors.email"
        class="text-red-500"
      >{{ form.errors.email }}</span>
    </div>
    <div class="flex flex-col gap-2">
      <p class="primary-color">
        {{ $t('home.contact_us.form.message') }}
        <span class="text-red-500">*</span>
      </p>
      <textarea
        v-model="form.message"
        :placeholder="$t('home.contact_us.form.message_placeholder')"
        class="rounded-2xl p-4 border w-full h-40"
      />
      <span
        v-if="form.errors.message"
        class="text-red-500"
      >{{ form.errors.message }}</span>
    </div>
    
    <!-- Pass isFormValid as a prop to SubmitBtn -->
    <SubmitBtn :is-form-valid="isFormValid" />
    
    <!-- Display success message -->
    <div
      v-if="page.props.flash.message"
      class="text-green-500 mt-4"
    >
      {{ page.props.flash.message }}
    </div>
  </form>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import SubmitBtn from '@WebsiteShared/components/SubmitBtn.vue';
import { computed } from 'vue';

const page = usePage();
const form = useForm({
  first_name: null,
  last_name: null,
  email: null,
  message: null,
});

// Computed property to check if all form fields are filled
const isFormValid = computed(() => {
  return !!(form.first_name && form.last_name && form.email && form.message);
});

function submit() {
  form.post('/contact-us', {
    preserveScroll: true,
    preserveState: true,
    onSuccess: () => {
      form.reset();
      // Remove the flash message after 5 seconds
      setTimeout(() => {
        page.props.flash.message = null;
      }, 5000);
    }
  });
}
</script>
