<template>
  <div class="flex flex-col items-center justify-evenly mt-2 p-16 relative max-md:flex-col">
    <div class="flex flex-col gap-3 md:gap-8 items-center w-[85dvw] max-md:w-screen">
      <div class="flex flex-col items-center gap-4">
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="w-32"
        >
        <h1 class="primary-color text-center mx-8 text-[40px] max-md:text-2xl">
          {{ $t('home.bank_services.title') }}
        </h1>
      </div>
      <p class="gray-color pb-3 mx-8 text-center max-md:text-sm">
        {{ $t('home.bank_services.subtitle') }}
      </p>

      <Carousel
        class="w-full relative"
        :dir="direction"
        :wrap-around="true"
        :snap-align="snapAlign"
        :items-to-show="itemsToShow"
        :breakpoints="carouselBreakpoints"
      >
        <Slide
          v-for="(item, i) in contents"
          :key="i"
        >
          <div class="w-full my-20">
            <ServicesCard :content="item" />
          </div>
        </Slide>

        <template #addons>
          <div class="absolute top-7 left-1/2 transform flex space-x-1 items-center">
            <Navigation
              :class="locale != 'ar' ? '' : 'rotate-180'"
              class="bg-[#00000019]  text-[#284478]  -translate-y-1/2   p-1 md:p-2 rounded-full hover:bg-[#00000033] flex items-center"
            />
          </div>
          <Pagination class="mt-4" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script setup>
import { getDirection } from '@/i18n';
import ServicesCard from '../Cards/ServicesCard.vue';
import { useI18n } from 'vue-i18n';

import { computed, ref } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
const { locale } = useI18n();

// Compute the direction based on the locale using getDirection
const direction = computed(() => getDirection(locale.value));

// carousel items count based on screen size =========
const itemsToShow = ref(3);
const snapAlign = ref('center')
const carouselBreakpoints = {
  1400: { itemsToShow: 3, snapAlign: 'center' }, // Large screens
  992: { itemsToShow: 2, snapAlign: 'start' },  // Tablets
  768: { itemsToShow: 2, snapAlign: 'start' },  // Smaller tablets
  0: { itemsToShow: 1.3, snapAlign: 'center' },  // Mobile devices
};

const contents = computed(() => {
  return [
    {
      title: 'home.bank_services.contents.investment_accounts.title',
      description: 'home.bank_services.contents.investment_accounts.description',
      img: new URL('@assets/images/home/investment_accounts.jpg', import.meta.url).href,
      link: '/services/investment-products',
    },
    {
      title: 'home.bank_services.contents.internal_transfers.title',
      description: 'home.bank_services.contents.internal_transfers.description',
      img: new URL('@assets/images/home/internal_transfers.jpg', import.meta.url).href,
      link: '/services/internal-transfers',
    },
    {
      title: 'home.bank_services.contents.current_account.title',
      description: 'home.bank_services.contents.current_account.description',
      img: new URL('@assets/images/home/current_account.jpg', import.meta.url).href,
      link: '/services/current-accounts',
    },
  ];
});
</script>