<template>
  <div>
    <h1>This is the show financials page</h1>
    <!-- Add your template code here -->
  </div>
</template>

<script>


import Layout from '@WebsiteShared/MainLayout.vue';

export default {
  layout: Layout,
  props: {
    financials: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // Log the news data to the console
    console.log('Financials:', this.financials);
  },
}
</script>
