<template>
  <div class="">
    <!-- Render news data if available -->
    <div
      v-if="allCareers.length"
      class="flex flex-col items-center py-20"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 place-items-center xl:grid-cols-3  gap-y-10 pb-20 w-10/12">
        <div
          v-for="(item, index) in allCareers"
          :key="index"
          class="w-full flex flex-col hala"
        >
          <CareerCard
            :content="item"
            :index="index"
          />
        </div>
      </div>

      <!-- Load More Button -->
      <LoadMoreButton
        :pagination="pagination"
        :load-more="loadMore"
        :button-text="$t('news.show_more_btn')"
      />
    </div>

    <div v-else>
      <NoResult
        :title="$t('careers.no_result.title')"
        :description="$t('careers.no_result.description')"
      />
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import CareerCard from './Cards/CareerCard.vue';
import NoResult from '@WebsiteShared/components/NoResult.vue';
import LoadMoreButton from '@components/LoadMoreButton.vue';
import { router } from '@inertiajs/vue3';

export default {
  components: {
    CareerCard,
    NoResult,
    LoadMoreButton,
  },
  layout: Layout,
  props: {
    careers: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      allCareers: [...this.careers.data],
      pagination: this.careers.links,
      currentPage: this.careers.meta.current_page,
    };
  },

  mounted(){
    console.log("card",this.allCareers)
  },
  methods: {
  loadMore() {
    if (this.pagination.next) {
      // Parse the next page URL to get the page number
      const nextPageUrl = new URL(this.pagination.next);
      const nextPageNumber = nextPageUrl.searchParams.get('page');
      
      // Use router.post with the base URL and pass the page as a parameter, not in the URL
      router.post('/careers', { page: nextPageNumber }, {
        preserveScroll: true,
        replace: false,
        preserveState: true,
        onSuccess: (page) => {
          // Append new items to the existing allCareers array
          this.allCareers = [...this.allCareers, ...page.props.careers.data];
          // Update pagination links and current page
          this.pagination = page.props.careers.links;
          this.currentPage = page.props.careers.meta.current_page;
        },
      });
    }
  },
}
};
</script>
