<template>
  <div class="container text-[#626c83] mx-auto py-20 font-bold">
    <!-- Features Section -->
    <div>
      <h2 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
        {{ $t("internal_transfers.featuresTitle") }}
      </h2>
      <ul class="mt-8 text-xl min-text-sm mx-14 list-disc marker:text-[#daab00] leading-8">
        <li>{{ $t("internal_transfers.feature1") }}</li>
        <li>{{ $t("internal_transfers.feature2") }}</li>
        <li>{{ $t("internal_transfers.feature3") }}</li>
        <li>{{ $t("internal_transfers.feature4") }}</li>
      </ul>
    </div>
    
    <!-- Transfer Steps Section -->
    <div class="mt-16">
      <h2 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
        {{ $t("internal_transfers.how_to_transfer") }}
      </h2>
      <div class="flex justify-center items-center mt-10 mx-auto">
        <!-- Desktop Image -->
        <img
          :src="locale == 'ar' ? imageDesktop_ar : imageDesktop_en"
          alt="how to transfer desktop"
          class="hidden md:block"
        >

        <!-- Mobile Image -->
        <img
          :src="locale == 'ar' ? imageMobile_ar : imageMobile_en"
          alt="how to transfer mobile"
          class="block md:hidden"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import { useI18n } from 'vue-i18n';

  export default {
    layout: Layout,
    setup() {
      const {locale} = useI18n();
      const imageDesktop_ar = new URL('@assets/images/services/internal-transfers/how-to-transfer-desktop-ar.svg', import.meta.url).href;
      const imageMobile_ar = new URL('@assets/images/services/internal-transfers/how-to-transfer-mobile-ar.svg', import.meta.url).href;
      const imageDesktop_en = new URL('@assets/images/services/internal-transfers/how-to-transfer-desktop-en.svg', import.meta.url).href;
      const imageMobile_en = new URL('@assets/images/services/internal-transfers/how-to-transfer-mobile-en.svg', import.meta.url).href;

      return { locale,imageDesktop_ar, imageMobile_ar ,imageDesktop_en,imageMobile_en};
    },
  };
</script>

<style lang="scss" scoped>
</style>
