<template>
  <AboutTheBank
    :content="{ title: 'telephone_banking_services', count: 12 }"
    title-style=""
  >
    <template #second-title>
      <div>
        <h1 class="primary-font lg:text-3xl md:text-2xl text-xl font-bold ">
          {{ $t(`telephone_banking_services.second_title`) }}
        </h1>
      </div>
    </template>
    <template #description>
      <div class="flex items-center justify-center mt-10">
        <p class="secondary-font text-center w-9/12 max-sm:w-11/12 px-1 leading-7">
          {{ $t('telephone_banking_services.description') }}
        </p>
      </div>
    </template>
  </AboutTheBank>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';

export default {
  components: {
    AboutTheBank,
  },
  layout: Layout,
}
</script>

<style scoped></style>