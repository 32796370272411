<template>
  <div>
    <div class="flex flex-col justify-center items-center my-20">
      <div
        v-for="item in data"
        :key="item.id"
        class="flex flex-col justify-center items-center my-5 w-full"
      >
        <div
          class="w-4/5 h-auto border-2 rounded-3xl flex flex-col justify-center p-10"
          :class="item.id % 2 === 0 ? 'bg-[#284478]' : 'bg-[#edf9ff]'"
        >
          <h1 :class="item.id % 2 === 0 ? 'text-[#edf9ff]' : 'primary-color'">
            {{ $t(item.title) }}
          </h1>
          <template v-if="item.id === 3">
            <div class="pt-10 pb-6 max-sm:pb-0 gray-color text-xl leading-8 flex flex-col">
              <li
                v-for="value in valuesData"
                :key="value.id"
                class="font-semibold text-black"
              >
                {{ $t(value.title) }}
                <p class="gray-color mx-6  max-sm:pb-0 w-3/4 max-md:w-full ">
                  {{ $t(value.description) }}
                </p>
              </li>
            </div>
          </template>
          <p
            v-else
            class="pt-10 pb-6 max-sm:pb-0 w-3/4 max-md:w-full leading-normal"
            :class="item.id % 2 === 0 ? 'text-[#edf9ff]' : 'gray-color'"
          >
            {{ $t(item.description) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import { useI18n } from 'vue-i18n';
export default {
  layout: Layout,
  setup() {
    const { t } = useI18n();
    const data = [
      { id: 1, title: 'our_vision.vision', description: 'our_vision.vision_description' },
      { id: 2, title: 'our_vision.our_goal', description: 'our_vision.our_goal_description' },
      { id: 3, title: 'our_vision.our_values', description: 'our_vision.our_message_description' }
    ];
    const valuesData = [
      { id: 1, title: 'our_vision.value_one', description: "our_vision.value_one_description" },
      { id: 2, title: 'our_vision.value_two', description: "our_vision.value_two_description" },
      { id: 3, title: 'our_vision.value_three', description: "our_vision.value_three_description" },
      { id: 4, title: 'our_vision.value_four', description: "our_vision.value_four_description" },
      { id: 5, title: 'our_vision.value_five', description: "our_vision.value_five_description" },
    ];

    return { t, data, valuesData };
  },
}
</script>

<style lang="scss" scoped></style>