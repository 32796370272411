<template>
  <div class="py-32 flex justify-center items-center">
    <div class="flex max-lg:flex-col justify-between px-28 gap-10 max-sm:px-6 w-full">
      <div class="w-1/2 max-lg:w-full flex flex-col gap-92">
        <h1 class="text-[#284478]">
          {{ $t('careers.employment_form.header_title') }}
        </h1>
        <p class="text-[#626c83] mt-10 leading-7 whitespace-pre-line font-bold">
          {{ $t('careers.employment_form.header_description') }}
        </p>
        <p class="text-[#626c83] mt-10 leading-7 whitespace-pre-line font-bold">
          {{ $t('careers.employment_form.sub_description') }}
        </p>
      </div>
      <!-- <div class="w-1/2 max-lg:w-full flex flex-col gap-92">
        <h1 class="text-[#284478]">{{$t('careers.job_details.title')}}</h1>
        <div class="flex mt-6 gap-4 items-start" v-for="item in data" :key="item.id">
            <img :src="item.img" alt="location"/>
            <div class="flex flex-col gap-2">
                <p class="primary-color font-bold">{{$t(item.title)}}</p>
                <p class="text-[#40444c] text-lg">الفرع الرئيسي - المكلا - الديس</p>
            </div>
        </div>
        <h1 class="mt-20 primary-color whitespace-nowrap">{{ $t('careers.employment_form.requirement') }}</h1>
           <ul class="mt-8 text-xl min-text-sm mx-14 list-disc marker:text-[#daab00] leading-8">
        <li>{{ $t("internal_transfers.feature1") }}</li>
        <li>{{ $t("internal_transfers.feature2") }}</li>
        <li>{{ $t("internal_transfers.feature3") }}</li>
        <li>{{ $t("internal_transfers.feature4") }}</li>
      </ul>
    </div> -->
      <div class="w-1/2 max-lg:w-full ">
        <EmploymentForm :education-level="educationLevel" />
      </div>
    </div>
  </div>
</template>
<script >
import Layout from '@WebsiteShared/MainLayout.vue';
import EmploymentForm from './Cards/EmploymentForm.vue';
import { computed } from 'vue';
export default {    
    components: {
      EmploymentForm
    },
    layout: Layout,
    props:{
        educationLevel:{
          type:Array,
          default: ()=>[],
        }
    },
setup(){
const data = computed(() => {
  return [
    {
      id:1,
      title: 'careers.job_details.location',
      img: new URL('@assets/images/icons/job-location.svg', import.meta.url).href,
    },
    {
      id:2,
      title:'careers.job_details.publish_date',
      img: new URL('@assets/images/icons/job-date.svg', import.meta.url).href,
    },
    {
      id:3,
      title: 'careers.job_details.last_date',
      img: new URL('@assets/images/icons/job-time.svg', import.meta.url).href,
    },
  ];
});
return {data}
},
mounted(){
    // console.log(this.educationLevel,'res')
}
} 


</script>

<style scoped>
</style>