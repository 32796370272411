<template>
  <div class="mt-16">
    <h1 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
      {{ $t(`${content.file_name}.faq.title`) }}
    </h1>
    <div class="w-full px-7 md:px-10 xl:px-2 py-4 ">
      <div class="mx-auto w-full max-w-5xl my-8 ">
        <div
          v-for="(faq, index) in faqs"
          :key="index"
          class="bg-[#fbfbf9] my-8 px-4 rounded-xl"
        >
          <button
            class="flex w-full items-start gap-x-5 justify-between rounded-lg text-left lg:text-2xl md:text-base sm:text-sm font-bold text-slate-800 focus:outline-none p-5"
            @click="toggleAnswer(index)"
          >
            <span class="text-[#284478] ">{{ $t(`${content.file_name}.faq.questions.${index}.question`) }}</span>
            <img
              src="@assets/images/icons/arrow-faq.svg"
              alt="Icon"
              class="mt-1.5 md:mt-0 flex-shrink-0 h-5 w-5 text-[#5B5675]"
              :class="{ 'rotate-180': faq.showAnswer }"
            >
          </button>
          <div
            v-if="faq.showAnswer"
            class="answer pt-2 pb-5 px-5 text-sm lg:text-base text-[#343E3A] font-medium"
          >
            {{ $t(`${content.file_name}.faq.questions.${index}.answer`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type:Object,
      default: ()=>({}),
    },
    faqs:{
      type: Array,
      default:()=>[],
    },
    toggleAnswer: {
      type:Function,
      default:()=>{}
    },
  },
};
</script>
