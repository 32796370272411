<template>
  <AboutTheBank
    :content="{ title: 'charitable_associations_and_institutions', count: 1 }"
    title-style=""
  />
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';

export default {
  components: {
    AboutTheBank,
  },
  layout: Layout,
}
</script>

<style scoped></style>