<template>
  <div>
    <h1>This is the sliders page</h1>
    <!-- Render news data if available -->
    <div v-if="sldiers && sliders.length">
      <ul>
        <li
          v-for="item in sliders"
          :key="item.id"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div v-else>
      <p>No sliders available.</p>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';

export default {
  layout: Layout,
  props: {
    news: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    // Log the news data to the console
    console.log('Sliders:', this.sliders);
  },
}
</script>
