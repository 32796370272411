<template>
  <div
    class="branch-map-popup primary-bg-color text-white  rounded-2xl p-4 flex flex-col text-start"
  >
    <!-- Logo Section -->
    <div class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
      <img
        src="@assets/images/logos/aboutLogo.svg"
        alt="Logo"
        class="w-10 h-10"
      >
    </div>
    <!-- branch type -->
    <div>
      <p
        :class="[
          'rounded-lg px-3 py-1 text-sm  mt-2 text-center whitespace-nowrap inline-block',
          `bg-${branch.type.tailwindColor}-400 text-${branch.type.tailwindColor}-900`
        ]"
      >
        {{ branch.type.label }}
      </p>
    </div>
    <!-- Branch Name -->
    <h3 class="text-lg font-bold">
      {{ branch.name }}
    </h3>

    <!-- Address -->
    <a
      :href="`https://www.google.com/maps/search/?api=1&query=${branch.latitude},${branch.longitude}`"
      target="_blank"
      class="text-white "
    >

      <p class="text-sm  mt-1">
        {{ branch.governorate }} - {{ branch.address }}
      </p>

    </a>
    <!-- Timings and Contact -->
    <div class="flex justify-between items-center w-full mt-6 max-md:flex-col gap-2">
      <!-- Phone Number -->
      <a
        :href="`tel:${branch.phone}`"
        class="flex items-center gap-1 text-lg"
      >
        <img
          src="@assets/images/icons/number.svg"
          alt="phone"
        >
        <span class="text-xs text-white">{{ branch.phone }}</span>
      </a>
      <!-- Timing -->
      <div class="flex items-center gap-1 text-lg">
        <img
          src="@assets/images/icons/clock.svg"
          alt="phone"
        >
        <span class="text-xs whitespace-nowrap">
          <!-- Display timing or "24 hours" if it's open 24/7 -->
          {{ branch.is_24_hours ? '24 Hours' : `${branch.open_time} - ${branch.close_time}` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
};
</script>
