<template>
  <AboutTheBank
    :content="{ title: 'sharia_compliance', count: 4 }"
    title-style="dotted"
    description-style="dotted"
  />
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
import AboutTheBank from '@components/AboutTheBank.vue';

export default {
  components: {
    AboutTheBank,
  },
  layout: Layout,
}
</script>

<style scoped></style>