<template>
  <div class="flex justify-evenly items-center  mt-10 p-10 relative max-md:flex-col">
    <div class="w-96 max-md:w-full ">
      <img
        class="max-md:w-52 w-96"
        :src="appImage"
        alt="logo"
      >
    </div>
    <div class="flex flex-col items-start mt-10 w-5/12 max-md:w-full">
      <div class="flex flex-col gap-4 items-center">
        <img
          class="w-32"
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
        >
        <h1 class="primary-font max-sm:text-2xl">
          {{ $t('home.bank_app.title') }}
        </h1>
      </div>
      <p class="gray-color pt-6 pb-3 font-bold">
        {{ $t('home.bank_app.bank_solution') }}
      </p>
      <p class="gray-color text-lg max-md:text-sm">
        {{ $t('home.bank_app.description') }}
      </p>

      <div class="flex items-center mt-8 gap-3">
        <!-- Link to App Store - Opens in new tab -->
        <a
          href="https://apps.apple.com/ye/app/hadhramout-bank/id6563143219?uo=2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="max-sm:w-28"
            src="@assets/images/home/appstore.svg"
            alt="App Store"
          >
        </a>

        <!-- Link to Google Play - Opens in new tab -->
        <a
          href="https://play.google.com/store/apps/details?id=com.icsfs.hadhramout&pcampaignid=web_share"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="max-sm:w-28"
            src="@assets/images/home/googleplay.svg"
            alt="Google Play"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
const appImage = computed(() => {
  return locale.value === 'ar'
    ? new URL('@assets/images/home/app.webp', import.meta.url).href
    : new URL('@assets/images/home/app-en.webp', import.meta.url).href;
});
</script>

<style scoped>
/* Add your styles here */
</style>
