<template>
  <div class="flex gap-6 mt-6 z-10">
    <a
      v-for="(media) in socialMediaLinks"
      :key="media.platform"
      :href="media.link"
      target="_blank"
      :aria-label="media.platform"
    >
      <img
        :src="getImageUrl(media.platform)"
        :alt="`${media.platform} icon`"
        class="social-media-icon"
      >
    </a>
  </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed, defineProps } from 'vue';

// Access social media links from page props
const pageProps = usePage().props;
const errorStatuses = [404, 500, 403, 503];
const socialMediaLinks = errorStatuses.includes(pageProps.status) 
    ? [] 
    : pageProps.settings?.general?.social_media_links || [];
console.log("socialMediaLinks", socialMediaLinks);

// Define the color prop directly
const props = defineProps({
  color: {
    type: String,
    default: 'white' // Possible values: 'white' or 'default'
  }
});

// Define computed properties for media icons
const mediaIcons = computed(() => ({
  white: {
    facebook: new URL('@assets/images/icons/media/facebook.svg', import.meta.url).href,
    twitter: new URL('@assets/images/icons/media/twitter.svg', import.meta.url).href,
    instagram: new URL('@assets/images/icons/media/instagram.svg', import.meta.url).href,
    youtube: new URL('@assets/images/icons/media/youtube.svg', import.meta.url).href,
    linkedin: new URL('@assets/images/icons/media/linkedin.svg', import.meta.url).href,
  },
  default: {
    facebook: new URL('@assets/images/icons/media/navbar/facebook.svg', import.meta.url).href,
    twitter: new URL('@assets/images/icons/media/navbar/twitter.svg', import.meta.url).href,
    instagram: new URL('@assets/images/icons/media/navbar/instagram.svg', import.meta.url).href,
    youtube: new URL('@assets/images/icons/media/navbar/youtube.svg', import.meta.url).href,
    linkedin: new URL('@assets/images/icons/media/navbar/linkedin.svg', import.meta.url).href,
  }
}));

// Function to get the image URL based on the name of the platform
function getImageUrl(name) {
  return mediaIcons.value[props.color][name];
}
</script>

<style scoped>
.social-media-icon {
  width: 32px;
  transition: transform 0.2s;
}

.social-media-icon:hover {
  filter: invert(30%) sepia(96%) saturate(500%) hue-rotate(0deg);
}
</style>
