<template>
  <div>
    <h2 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
      {{ $t(`${content.file_name}.account_features.title`) }}
    </h2>
    <ul class="mt-8 text-xl min-text-sm mx-14 list-disc marker:text-[#daab00] leading-8">
      <li
        v-for="feature in features"
        :key="feature"
      >
        {{ $t(`${content.file_name}.account_features.${feature}`) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    content:{
      type:Object,
      default: ()=>({}),
    },
    features: {
      type:Array,
      default: ()=>[],
    },
  },
};
</script>
