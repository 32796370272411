<template>
  <ShowPageHeader :title="news.title" />
  <div class="flex flex-col gap-10 items-center min-h-96 pt-10 px-3 md:p-20">
    <div class="w-full flex flex-col md:flex-row max-md:text-center items-start gap-10">
      <img
        class="w-1/4 max-lg:w-1/3 max-md:w-full rounded-3xl"
        :src="news.poster"
        alt=""
      >
      <!-- Use v-html to render the description as HTML -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        class="editor-wysiwyg-font"
        v-html="news.description"
      />
    </div>
    <div>
      <Link
        href="/news"
        class="primary-bg-color text-white w-52 h-10 rounded-2xl flex items-center justify-around mt-10 relative"
      >
        {{ $t('news.back_btn') }}
        <img
          :class="locale == 'ar' ? 'rotate-180' : ''"
          src="@assets/images/icons/left-arrow.svg"
          alt="logo"
        >
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="absolute w-20 top-0 opacity-60"
          :class="locale == 'ar' ? 'right-[10px]' : 'left-[10px] scale-x-[-1]'"
        >
      </Link>
    </div>
    <div class="w-full flex flex-col gap-10">
      <div>
        <p class="primary-font">
          {{ $t('news.more_news') }}
        </p>
      </div>
      <div class="flex flex-col w-full">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-items-center xl:grid-cols-4 w-11/12 mx-auto items-center gap-y-10"
        >
          <div
            v-for="item in other_news"
            :key="item.id"
            class="w-full "
          >
            <NewsCard :content="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowPageHeader from '@components/ShowPageHeader.vue';
import { Link } from '@inertiajs/vue3';
import NewsCard from '@WebsiteShared/components/NewsCard.vue';
import Layout from '@WebsiteShared/MainLayout.vue';
import { useI18n } from 'vue-i18n';


export default {

  components: {
    NewsCard,
    ShowPageHeader,
    Link
  },
  layout: Layout,
  props: {
    news: {
      type: Object,
      default: () => ({}),
    },
    other_news: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { locale } = useI18n();
    return { locale };
  },
}
</script>
