<template>
  <div>
    <div class="flex justify-center items-center">
      <div class="container py-20">
        <h1 class="primary-color text-4xl max-sm:text-2xl mx-10">
          {{ $t('individual_services.account_types') }}
        </h1>
        <div class="flex justify-center items-center flex-wrap">
          <div
            v-for="item in data"
            :key="item.id"
            class="w-96 h-72 border-2 rounded-3xl flex flex-col p-5 mx-5 mt-5"
            :class="item.id%2==0?'bg-[#284478]':'bg-[#edf9ff]'"
          >
            <img
              :src="item.img"
              alt="current-account"
              class="icon-size"
            >
            <p
              class="text-2xl font-bold py-6"
              :class="item.id%2==0?'text-[#edf9ff]':'primary-color'"
            >
              {{ $t(item.title) }}
            </p>
            <p
              class="h-28"
              :class="item.id%2==0?'text-[#edf9ff]':'text-[#40444c]'"
            >
              {{ $t(item.description) }}
            </p>
            <Link
              :href="item.link"
              class="flex items-center cursor-pointer"
            >
              <div class="flex items-center">
                <p
                  class="text-[16px] mx-2"
                  :class="item.id%2==0?'text-[#edf9ff]':'primary-color'"
                >
                  {{ $t('individual_services.read_more') }}
                </p>
                <img
                  :src="getArrowImage(item.id)"
                  alt=" arrow"
                >
              </div>
            </Link>
          </div>
        </div>
        <div class="">
          <h1 class="primary-color mt-20 text-4xl max-sm:text-2xl mx-10">
            {{ $t('individual_services.open_account') }}
          </h1>
          <ul class="p-10 list-none mx-10">
            <li class="primary-color text-xl font-bold mb-4">
              {{ $t('individual_services.visit_branch') }}
            </li>
            <p class="text-[#40444c] mb-4">
              {{ $t('individual_services.visit_branch_description') }}
            </p>
            <li class="primary-color text-xl font-bold mb-4">
              {{ $t('individual_services.online_app') }}
            </li>
            <p class="text-[#40444c] mb-4">
              {{ $t('individual_services.online_app_description') }}
            </p>
            <li
              class="primary-color text-xl font-bold mb-4"
            >
              {{ $t('individual_services.mobile_banking') }}
            </li>
            <p class="text-[#40444c]">
              {{ $t('individual_services.mobile_banking_description') }}
            </p>
          </ul>
        </div>
        <div class=" mx-10">
          <h1 class="primary-color mt-10 text-4xl max-sm:text-2xl ">
            {{ $t('individual_services.require_document') }}
          </h1>
          <p class="text-[#40444c] mt-6">
            {{ $t('individual_services.to_open_acoount') }}
          </p>
          <ol
            type="1"
            class="mt-10 primary-color font-bold list-decimal list-inside"
          >
            <li>{{ $t('individual_services.Valid_identification') }}</li>
            <li class="mt-2">
              {{ $t('individual_services.Proof_of_address') }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import Layout from '@WebsiteShared/MainLayout.vue';
import { computed } from 'vue';
  export default {
    components: {
      Link
    },
     layout: Layout,
     setup() {
      //for read more arrow image
      const getArrowImage = (id) => {
        if (id % 2 === 0) {
           return new URL('@assets/images/services/circle-arrow-up-left-white.svg', import.meta.url).href;
        } else {
          return new URL('@assets/images/services/circle-arrow-up-left.svg', import.meta.url).href;
  }
};


const data = computed(() => {
  return [
    {
      id:1,
      title: 'individual_services.current_account',
      description: 'individual_services.current_account_description',
      img: new URL('@assets/images/services/current-account.svg', import.meta.url).href,
      link: '/services/current-accounts',
    },
    // {
    //   id:2,
    //   title:'individual_services.savings_account',
    //   description: 'individual_services.savings_account_description',
    //   img: new URL('@assets/images/services/saving-account.svg', import.meta.url).href,
    //   link: '/services/savings-accounts',
    // },
    {
      id:2,
      title: 'individual_services.investment-accounts',
      description: 'individual_services.investment_accounts_description',
      img: new URL('@assets/images/services/investment-account.svg', import.meta.url).href,
      link: '/services/investment-products',
    },
  ];
});
return {data ,getArrowImage}
     }
  }
</script>

<style lang="scss" scoped>
  ul li::before {
    content: "•";
    color: #284478;
    display: inline-block;
    width: 2em;
    margin-left: -1em;
  }
</style>