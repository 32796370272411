<template>
  <div class="container text-[#626c83] mx-auto py-20 font-bold">
    <!-- Features Section -->
    <FeaturesSection
      :content="{file_name: 'current_accounts'}"
      :features="features"
    />

    <!-- Required Documents Section -->
    <RequiredDocuments
      :content="{file_name: 'current_accounts'}"
      :data="data"
    />

    <!-- Fees And ChargesS ection -->
    <FeesAndCharges
      :content="{file_name: 'current_accounts'}"
      :rows="fees_and_charges_data"
    />

    <!-- FAQ Section -->
    <FAQSection
      :content="{file_name: 'current_accounts'}"
      :faqs="faqs"
      :toggle-answer="toggleAnswer"
    />
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue'
import FAQSection from '@components/Services/FAQSection.vue'
import FeaturesSection from '@components/Services/FeaturesSection.vue'
import FeesAndCharges from '@components/Services/FeesAndCharges.vue'
import RequiredDocuments from '@components/Services/RequiredDocuments.vue'

import { computed, ref } from 'vue'

export default {
  components: {
    FeaturesSection,
    RequiredDocuments,
    FeesAndCharges,
    FAQSection,
  },
  layout: Layout,
  setup() {
    const features = Array.from({ length: 9 }, (_, i) => `feature${i + 1}`)

    const data = computed(() => [
      {
        id: 1,
        title: 'current_accounts.required_documents.new_customers.title',
        description: [
          'current_accounts.required_documents.new_customers.account_agreement',
          'current_accounts.required_documents.new_customers.proof_identity',
          'current_accounts.required_documents.new_customers.proof_address',
        ],
        img: new URL('@assets/images/services/new-customer.svg', import.meta.url).href,
      },
      {
        id: 2,
        title: 'current_accounts.required_documents.existing_customers.title',
        description: ['current_accounts.required_documents.existing_customers.description'],
        img: new URL('@assets/images/services/existing-customer.svg', import.meta.url).href,
      },
      {
        id: 3,
        title: 'current_accounts.required_documents.non_profit_organizations.title',
        description: [
          'current_accounts.required_documents.non_profit_organizations.account_agreement',
          "current_accounts.required_documents.non_profit_organizations.official_approval",
          "current_accounts.required_documents.non_profit_organizations.incorporate_documents",
        ],
        img: new URL('@assets/images/services/non-profit-organizations.svg', import.meta.url).href,
      },
      {
        id: 4,
        title: 'current_accounts.required_documents.commercial_companies.title',
        description: [
          'current_accounts.required_documents.commercial_companies.account_agreement',
          "current_accounts.required_documents.commercial_companies.commercial_register",
          "current_accounts.required_documents.commercial_companies.tax_card",
        ],
        img: new URL('@assets/images/services/commerical.svg', import.meta.url).href,
      },
    ])

const fees_and_charges_data = computed(() => 
  [
    { 
      id: 1, 
      service: `current_accounts.fees_and_charges.services[0].service`, 
      fee: `current_accounts.fees_and_charges.services[0].fee`, 
    },

    { 
      id: 2, 
      service: 'current_accounts.fees_and_charges.services[1].service', 
      fee: 'current_accounts.fees_and_charges.services[1].fee'
    },
    {
      id: 3,
      service: 'current_accounts.fees_and_charges.services[2].service',
      fee: 'current_accounts.fees_and_charges.services[2].fee',
    },  
    {
      id: 4,
      service: 'current_accounts.fees_and_charges.services[3].service',  
      fee: 'current_accounts.fees_and_charges.services[3].fee',    
    },
    {
      id: 5,
      service: 'current_accounts.fees_and_charges.services[4].service',  
      fee: 'current_accounts.fees_and_charges.services[4].fee',    
    }]
  
);

    const faqs = ref([
      { showAnswer: false },
      { showAnswer: false },
      { showAnswer: false },
    ])

    const toggleAnswer = (index) => {
      faqs.value[index].showAnswer = !faqs.value[index].showAnswer
    }
    console.log('fees_and_charges_data',fees_and_charges_data)
    return { data, features, faqs, fees_and_charges_data,toggleAnswer }
  },
}
</script>
