<template>
  <div class="flex flex-col items-center p-10">
    <img
      src="@assets/images/logos/aboutLogo.svg"
      alt="logo"
    >
    <img
      src="@assets/images/home/main-pattern.svg"
      alt="pattern"
      class="mt-8"
    >
    <h1 class="text-[#284478] mt-6">
      {{ $t(`page_headers.about.board.header_title`) }}
    </h1>
    <div class="grid grid-cols-4 max-lg:grid-cols-3 max-sm:grid-cols-1 mt-16 gap-4 w-full px-10 ">
      <div
        v-for="item in 8"
        :key="item.id"
        class="w-full h-full border-0 border-solid border-black rounded-2xl relative card"
      >
        <img
          src="@assets/images/logos/man.png"
          alt="logo"
          class="object-cover h-full w-full rounded-2xl"
        >
        <div class="flex flex-col absolute bottom-10 right-2">
          <h1 class="text-xl max-md:text-lg max-lg:text-md">
            الأستاذ سامر اليوسف
          </h1>
          <p class="max-md:text-sm">
            رئيس مجلس الإدارة
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';
export default {
  layout: Layout,
}
</script>
<style lang="scss" scoped>
.card::before {
  content: "";
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(39, 67, 133, 0), rgba(39, 67, 133, 0), rgba(39, 67, 133, 0.7), rgba(39, 67, 133, 1));
  width: 100%;
  height: 100%;
  border-radius: 18px;
}
</style>