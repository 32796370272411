<template>
  <div>
    <ShowPageHeader
      :title="career.title"
      :model-path="modelPath"
      :is-link-sub-title="isLinkSubTitle"
      :link-type="career.type"
    />


    <div class="py-32 flex justify-center items-center">
      <div class="flex max-lg:flex-col justify-between px-28 gap-10 max-xl:px-6 max-sm:px-6 w-full">
        <div
          v-if="career.type!=='vacancies'"
          class="w-1/2 max-lg:w-full flex flex-col gap-92"
        >
          <h1 class="text-[#284478]">
            {{ $t('careers.employment_form.header_title') }}
          </h1>
          <p
            class="text-[#626c83] mt-10 leading-7 whitespace-pre-line font-bold"
          >
            {{ $t('careers.employment_form.header_description') }}
          </p>
          <p
            class="text-[#626c83] mt-10 leading-7 whitespace-pre-line font-bold"
          >
            {{ $t('careers.employment_form.sub_description') }}
          </p>
        </div>
        <div
          v-else
          class="w-1/2 max-lg:w-full flex flex-col gap-92"
        >
          <h1 class="text-[#284478] max-sm:text-center">
            {{ $t('careers.job_details.title') }}
          </h1>
          <div class="flex mt-6 gap-4 items-start">
            <img
              src="@assets/images/icons/job-location.svg"
              alt="location"
            >
            <div class="flex flex-col gap-2">
              <p class="primary-color font-bold">
                {{ $t('careers.job_details.location') }}
              </p>
              <p class="text-[#40444c] text-lg">
                {{ career.location }}
              </p>
            </div>
          </div>
          <div class="flex mt-6 gap-4 items-start">
            <img
              src="@assets/images/icons/job-date.svg"
              alt="location"
            >
            <div class="flex flex-col gap-2">
              <p class="primary-color font-bold">
                {{ $t('careers.job_details.publish_date') }}
              </p>
              <p class="text-[#40444c] text-lg">
                {{ career.published_at }}
              </p>
              <p
                v-if="!career.published_at"
                class="text-[#be2222] text-lg"
              >
                {{ $t('careers.job_details.not_published') }}
              </p>
            </div>
          </div>
          <div class="flex mt-6 gap-4 items-start">
            <img
              src="@assets/images/icons/job-time.svg"
              alt="location"
            >
            <div class="flex flex-col gap-2">
              <p class="primary-color font-bold">
                {{ $t('careers.job_details.last_date') }}
              </p>
              <p class="text-[#40444c] text-lg">
                {{ career.due_date }}
              </p>
            </div>
          </div>
          <h1
            class="mt-20 primary-color max-sm:text-center"
          >
            {{ $t('careers.job_details.requirement') }}
          </h1>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            class="mt-4 text-xl min-text-sm mx-14 editor-wysiwyg-font "
            v-html="career.description"
          />
        </div>
        <div class="w-1/2 max-lg:w-full">
          <EmploymentForm
            :education-level="education_level"
            :career="career"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import ShowPageHeader from '@components/ShowPageHeader.vue';
import Layout from '@WebsiteShared/MainLayout.vue';
import EmploymentForm from './Cards/EmploymentForm.vue';
import { computed } from 'vue';
export default {    
    components: {
      EmploymentForm,
      ShowPageHeader,
    },
    layout: Layout,
    props:{
      education_level:{
          type: Array,
          default:()=>[],
        },
        career:{
          type: Object,
          default:()=>({}),
        },
    },
setup(){
  const modelPath="careers"
const data = computed(() => {
  return [
    {
      id:1,
      title: 'careers.job_details.location',
      img: new URL('@assets/images/icons/job-location.svg', import.meta.url).href,
    },
    {
      id:2,
      title:'careers.job_details.publish_date',
      img: new URL('@assets/images/icons/job-date.svg', import.meta.url).href,
    },
    {
      id:3,
      title: 'careers.job_details.last_date',
      img: new URL('@assets/images/icons/job-time.svg', import.meta.url).href,
    }, 
  ];
});
const isLinkSubTitle=false 
return {data,modelPath,isLinkSubTitle}
},
mounted(){
    console.log(this.career,'res')
}
} 


</script>

<style scoped>
</style>