<template>
  <div class="mt-16">
    <h2 class="primary-color font-bold text-4xl max-sm:text-2xl mx-10">
      {{ $t('investment_products.types_of_investment_products.title') }}
    </h2>
    <ul class="mt-8 text-xl min-text-sm mx-14 list-disc marker:text-[#daab00] leading-8">
      <li
        v-for="type in typeKeys"
        :key="type"
      >
        {{ $t(`investment_products.types_of_investment_products.${type}`) }}
      </li>
    </ul>
  </div>
</template>
  
  <script>
  export default {
    props: {
      typeKeys: {
      type:Array,
      default: ()=>[],
    },
  },
};
  </script>
  