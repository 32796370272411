<template>
  <div class="w-screen primary-bg-color relative">
    <div class="px-5 md:ps-20 pb-10 gap-4 w-3/5 max-md:w-full flex flex-col justify-end text-white pt-40">
      <img
        src="@assets/images/logos/footer-icon.svg"
        alt="logo"
        class="absolute left-0 bottom-0 w-96"
      >
      <h1>{{ title }}</h1>
      <p class="mt-4">
        <Link
          href="/"
          class="text-white hover:text-[#daab00]"
        >
          {{ $t('common.home') }}
        </Link>
        > 
        <template v-if="isLinkSubTitle || linkType==='vacancies'">
          <Link
            :href="`/${modelPath}`"
            class="text-white hover:text-[#daab00]"
          >
            {{ $t(`${modelPath}.name`) }}
          </Link>
          >
        </template>
        <span class="text-[#d8f0ff]">{{ title }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    Link,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    modelPath: {
      type: String,
      default: 'news',
    },
    isLinkSubTitle: {
      type: Boolean,
      default: true,
    },
    linkType:{
      type:String,
      default: ""
    }
  },
};
</script>

<style scoped>
</style>
