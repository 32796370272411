<template>
  <div
    class="w-full primary-bg-color flex flex-col items-center py-10 relative overflow-y-hidden mb-0"
  >
    <img
      src="@assets/images/logos/footerlogo.svg"
      alt="logo"
    >
    <div class="flex flex-row max-sm:flex-col max-sm:w-9/12">
      <!-- <p
        v-for="page in pages"
        :key="page.id"
        class="px-6 mt-6 font-bold cursor-default max-md:text-sm "
      >
    <a :href="page.link">{{$t(page.title)}}</a></p> -->
      <Link
        v-for="page in pages"
        :key="page.id"
        :href="page.link"
        class="px-6 mt-6 font-bold cursor-pointer max-md:text-sm z-10"
      >
        <p class="hover:text-[#daab00]">
          {{ $t(page.title) }}
        </p>
      </Link>

      <a
        href="/sitemap.xml"
        class="px-6 mt-6 font-bold cursor-pointer max-md:text-sm z-10"
      >
        <p class="hover:text-[#daab00]">{{ $t('footer.sitemap') }}</p>
      </a>
    </div>
    <div class="flex flex-row mt-8 gap-8 items-center ">
      <SocialMediaLinks />
    </div>
    <div class="w-full px-16 mt-12">
      <hr class=" w-full mx-auto px-8 bg-gray-200 ">
      <div class="px-4 mt-4 flex flex-row items-center justify-between max-sm:flex-col-reverse max-sm:gap-4">
        <div class="flex flex-col justify-center items-center gap-4">
          <p class="text-sm whitespace-nowrap ">
            {{ $t('footer.copyright') }} {{ new Date().getFullYear() }}
          </p>
        </div>
        <div class="z-10">
          <a
            href="https://bootfi.com"
            target="_blank"
          >
            <img
              src="@assets/images/logos/bootfi-logo.svg"
              alt="logo"
              class="cursor-pointer"
            >
          </a>  
        </div>
        <img
          src="@assets/images/logos/footer-icon.svg"
          alt="logo"
          class="absolute left-0 bottom-0 z-0"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import SocialMediaLinks from '@components/SocialMediaLinks.vue';
import { Link } from '@inertiajs/vue3';


const pages = [
  { id: 1, title: "footer.privacy_policy", link: "/privacy-policy" },
  { id: 2, title: "footer.terms", link: "/terms-and-conditions" },
  { id: 3, title: "footer.media_center", link: "/news" },
  { id: 4, title: "footer.branches", link: "/branches" },
  { id: 5, title: "footer.atm", link: "/branches?type=atm" },
];

// const errorStatuses = [404, 500, 403, 503];
// const social_media_links = errorStatuses.includes(props.status) 
//     ? [] 
//     : props.settings?.general?.social_media_links || [];
// const mediaImg = computed(() => ({
//     facebook: new URL('@assets/images/icons/media/facebook.svg', import.meta.url).href,
//     twitter: new URL('@assets/images/icons/media/twitter.svg', import.meta.url).href,
//     instagram: new URL('@assets/images/icons/media/instagram.svg', import.meta.url).href,
//     youtube: new URL('@assets/images/icons/media/youtube.svg', import.meta.url).href,
//     linkedin: new URL('@assets/images/icons/media/linkedin.svg', import.meta.url).href,
// }));
</script>

<style lang="scss" scoped>
</style>