<template>
  <div class="w-full flex flex-col items-start my-12">
    <div class="w-full flex flex-col items-center">
      <img
        class="max-md:w-24"
        src="@assets/images/logos/aboutLogo.svg"
        alt="logo"
      >
      <div class="flex flex-col items-center mt-10 gap-4">
        <img
          src="@assets/images/home/main-pattern.svg"
          alt="logo"
          class="w-32"
        >
        <h1 class="primary-font lg:text-4xl md:text-4xl text-3xl font-bold mx-8 text-center">
          {{ $t(`${content.title}.title`) }}
        </h1>
        <slot name="description" />
      </div>
    </div>
    <div class="w-10/12 ms-10 md:ms-40 mt-20 flex flex-col gap-14 ">
      <slot name="second-title" />
      <div
        v-for="i in content.count"
        :key="i"
        class="flex flex-col gap-2"
      >
        <p class="primary-color font-semibold lg:text-2xl md:text-xl text-lg">
          <span v-if="titleStyle === 'number'">{{ i }}.</span>
          <span v-else-if="titleStyle === 'dotted'">•</span>
          {{ $t(`${content.title}.content.${i}.title`) }}
        </p>
        <div>
          <p
            v-for="(line, index) in formattedDescription(i)"
            :key="`${i}-${index}`"
            class="flex items-start text-xl leading-9 max-md:text-base description-text"
          > 
            <span v-if="descriptionStyle === 'number' ">{{ index + 1 }}.</span>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="line" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@WebsiteShared/MainLayout.vue';

export default {
  layout: Layout,
  props: {
    content: {
      type: Object,
      required: true,
    },
    titleStyle: {
      type: String,
      default: '',
    },
    descriptionStyle: {
      type: String,
      default: '', 
    },
  },
  methods: {
    formattedDescription(index) {
      const description = this.$t(`${this.content.title}.content.${index}.description`);
      return description.split('\n').map(line => this.formatBoldText(line));
    },
    formatBoldText(text) {
      return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }
  },
};
</script>

<style scoped>
.description-text {
  color: #40444C;
}
</style>
